import { api } from '../api';
import { getMsalInstance} from '../Config/msalConfig';


const loginUser = async (user) => {
  const loginResponse = await api.post(`/auth/login`, user)
  return loginResponse;
};

const logoutUser = async () => {
  try {
    if (process.env.REACT_APP_SSO_ENABLED !== 'false') {
      const msalInstance = getMsalInstance();
      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(sessionStorage.getItem('msalHomeID')),
      };
      msalInstance.logoutRedirect(logoutRequest);
      await api.post(`/auth/logout`);
      window.location.replace('/#/login');
    } else {
      await api.post(`/auth/logout`);
      window.location.replace('/#/login');
      window.location.reload();
    }
  } catch (error) {
    console.error('Logout failed:', error);
    window.location.replace('/#/');
  }
};

const extendUserSession = async () => {
  const res = await api.post('/auth/extend');
  return res;
};

export {
  loginUser,
  logoutUser,
  extendUserSession,
}
