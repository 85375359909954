import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { Component } from 'react';
import Select from 'react-select';

import { BlueSwitch } from '../../general/components/BlueSwitch.js';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading.js';
import { api } from '../../api';
import WorkPermitOutputClient from '../../client/work-permit/WorkPermitOutputClient.js';


class OrderPermits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem('companyId'),
      workPermits: [],
      changesMade: false,
      isLoading: false,
      selectedRegion: null,
      countries: [],
      selectedCountry: null,
      blacklistedPermits: [],
      isEditMode: false,
      selectedPermitRankObject: null,
      addManualAssessed: [],
      removeManualAssessed: [],
    };
  } 

  componentDidMount() {
    if (!this.state.companyId) {
      window.location.replace('/#/');
    } else {
      this.fetchCompany();
      this.fetchCountries();
    }
    
    this.initBlacklistedPermits();
  }
  
  initBlacklistedPermits = () => {
    this.setState({blacklistedPermits: []});
  }

  async fetchCompany() {
    const response = await api.get('/companies/'+ this.state.companyId);
    this.setState({selectedCompany: response.data});
  }

  async fetchCountries() {
    const response = await api.get('/countries/');
    this.setState({countries: response.data});
  }

  onClickOrder = (index, orderType) => {
    this.setState({changesMade: true, isLoading:true}, () => {
      let updatedWorkPermits = this.state.workPermits;
      let permitToMoveUp = (orderType === "up") ? updatedWorkPermits[index] : updatedWorkPermits[index +1];
      let permitToMoveDown = (orderType === "up") ? updatedWorkPermits[index - 1] : updatedWorkPermits[index];

      permitToMoveUp.rank = permitToMoveUp.rank - 1;
      permitToMoveDown.rank = permitToMoveDown.rank + 1;
      
      updatedWorkPermits.sort(this.dynamicSort("rank"));
      
      this.setState({workPermits: updatedWorkPermits, isLoading: false})
    });
  }

  onCountrySelected = (event) => {
    let regionId = event.value.id;
    let selectedCountry = event.value;
    let selectedRegion = selectedCountry.regions.find(region => region.id.toString() === regionId.toString());
    this.setState({
      selectedRegion : selectedRegion,
      selectedCountry: selectedCountry
    }, () => this.fetchRankedPermits());
  }

  async fetchRankedPermits() {
    let allPermits = await api.get('/countries/'+this.state.selectedCountry.id+'/regions/'+this.state.selectedRegion.id+'/work-permits/'+this.state.companyId);

    allPermits = allPermits.data;
    let rankedPermits = await api.post('/work-permits/filter-work-permit-ranks', {company: this.state.companyId, region: this.state.selectedRegion.id});

    rankedPermits = rankedPermits.data;
    
    for (let i = 0 ; i < rankedPermits.length; i++) {
      let workPermit = allPermits.find(permit => permit.id === rankedPermits[i].workPermit.id);

      if (workPermit) {
        rankedPermits[i].workPermit.name = workPermit.workPermitRevisions[0].name;
        rankedPermits[i].workPermit.deloittePermitId = workPermit.workPermitRevisions[0].deloittePermitId;
        rankedPermits[i].rank = i + 1;
        rankedPermits[i].blacklist = this.getPermitBlacklistingDetail(workPermit);
        rankedPermits[i].manual = this.getPermitManualAssessedDetail(workPermit);
      }
    }
    this.setState({
      workPermits: rankedPermits
    });
  }

  getPermitBlacklistingDetail = (workPermit) => {
    let permitBlacklistInfo = {};
    permitBlacklistInfo.status = false;
    
    if (this.isBlacklistedPermitForCompany(workPermit)) {
      permitBlacklistInfo.id = workPermit.companyWorkPermitBlacklists[0].id;
      permitBlacklistInfo.status = true;
    }

    return permitBlacklistInfo;
  }

  getPermitManualAssessedDetail = (workPermit) => {
    let permitManualInfo = {};
    permitManualInfo.status = false;
    
    if (this.isManuallyAssessedForCompany(workPermit)) {
      permitManualInfo.id = workPermit.companyManuallyAssessedPermitList[0].id;
      permitManualInfo.status = true;
      permitManualInfo.content = workPermit.companyManuallyAssessedPermitList[0].output;
    }

    return permitManualInfo;
  }

  isBlacklistedPermitForCompany = (workPermit) => {
    return workPermit.companyWorkPermitBlacklists && workPermit.companyWorkPermitBlacklists.length > 0;
  }

  isManuallyAssessedForCompany = (workPermit) => {
    return workPermit.companyManuallyAssessedPermitList && workPermit.companyManuallyAssessedPermitList.length > 0;
  }

/**
 * Pass in the work permit rank object and set its manual status according to the toggle.
 * Add the work permit id to the list of changes to be made to the database, depending on whether the toggle is on or off.
 * @param {Object} permitRankObject Object {id, rank, blacklist {status}, manual {status}, workPermit}
 */
  updateManualStatus = (permitRankObject) => {
    this.setState({ changesMade: true });
    let tempWorkPermits = this.state.workPermits;
    const newIsManualStatus = !permitRankObject.manual.status;

    tempWorkPermits.filter(permit => permit.id === permitRankObject.id)[0].manual.status = newIsManualStatus;
    this.setState({ workPermits: tempWorkPermits });

    if (newIsManualStatus) {
      let tempPermitList = this.state.addManualAssessed;
      tempPermitList.push({workPermitId: permitRankObject.workPermit.id});
      this.setState({ addManualAssessed: tempPermitList });
    } else {
      let tempPermitList = this.state.removeManualAssessed;
      tempPermitList.push({workPermitId: permitRankObject.workPermit.id});
      this.setState({ removeManualAssessed: tempPermitList });
    }
  }

  updateManuallyAssessedPermits = async () => {
    if (this.state.addManualAssessed.length > 0) {
      await api.post(`/companies/${this.state.companyId}/add-manually-assessed-permits`, this.state.addManualAssessed);
      this.setState({ addManualAssessed: [] });
    }
    if (this.state.removeManualAssessed.length > 0) {
      await api.delete(`/companies/${this.state.companyId}/remove-manually-assessed-permits`, {data: this.state.removeManualAssessed});
      this.setState({ removeManualAssessed: [] });
    }
  }

  onClickBlacklistChange = (workPermit) => {
    this.setState({ changesMade: true });
    let workPermits = this.state.workPermits;

    for(var i = 0; i < workPermits.length; i++) {
      if (workPermits[i].id === workPermit.id) {
        workPermits[i].blacklist.status = !workPermits[i].blacklist.status;

        this.handleBlacklistingPermitChange(workPermits[i]);
        this.sendBlacklistedPermitToBottomOfRankings(workPermits, i);

        break;
      }
    }

    this.setState({
      workPermits
    });
  }

  handleBlacklistingPermitChange = (workPermit) => {
    let blacklistedPermits = this.state.blacklistedPermits;

    for (let i = 0; i < blacklistedPermits.length; i++) {
      if (blacklistedPermits[i].workPermit.id === workPermit.workPermit.id) {
        blacklistedPermits.splice(i, 1);

        this.setState({blacklistedPermits});

        return;
      }
    }

    blacklistedPermits.push(workPermit);

    this.setState({blacklistedPermits});
  }

  sendBlacklistedPermitToBottomOfRankings = (workPermits, index) => {
    let workPermit = workPermits[index];

    // If the permit has been blacklisted, send to the bottom
    if (workPermit.blacklist.status) {
      workPermits.splice(index, 1);
      workPermits.push(workPermit);
    }

    this.setState({workPermits});
  }

  async saveBlacklistedWorkPermits() {
    let permitsToBlacklist = this.getPermitsWithBlacklistingChangeByStatus(true);

    if (permitsToBlacklist.length > 0) {
      let url = '/companies/' + this.state.companyId + '/blacklist-work-permits';

      return await api.post(url, permitsToBlacklist);
     }

     return Promise.resolve();
  }

  async saveDeBlacklistedWorkPermits() {
    let permitsToDeBlacklist = this.getPermitsWithBlacklistingChangeByStatus(false);
    if (permitsToDeBlacklist.length > 0) {
      let url = '/companies/' + this.state.companyId + '/remove-work-permits-from-blacklist';
    
      return await api.delete(url, {data: permitsToDeBlacklist});
    }

    return Promise.resolve();
  }

  getPermitsWithBlacklistingChangeByStatus = (status) => {
    let permitsWithBlacklistChangeByStatus = [];
    
    this.state.blacklistedPermits.forEach((blp) => {
      if (blp.blacklist.status === status) {
        permitsWithBlacklistChangeByStatus.push(this.getBlacklistingChangeDto(blp));
      }
    });

    return permitsWithBlacklistChangeByStatus;
  }

  // If it is a new blacklisting, we need to send the work permit company as an object. If it's a de-blacklisting, we need to send the work permit blacklist id
  getBlacklistingChangeDto = (blp) => {
    return blp.blacklist.status
      ? { company: this.state.companyId, workPermit: blp.workPermit.id }
      : Number(blp.blacklist.id);
  };

  saveChanges = async () => {
    this.setState({isLoading: true});
    await Promise.all([this.saveBlacklistedWorkPermits(), this.saveDeBlacklistedWorkPermits()]);
    await this.updateManuallyAssessedPermits();
    await this.saveUpdatedWorkPermitRankings();
  
    this.fetchRankedPermits().then(()=> {
      alert('Permits updated successfully.');
      this.setState({changesMade: false, blacklistedPermits: []});
    });
  }

  saveUpdatedWorkPermitRankings = async () => {
    let updatedWorkPermitRankings = [];

    this.state.workPermits.forEach((workPermit, index) => {
      updatedWorkPermitRankings.push({rank: index + 1, company: this.state.companyId, workPermit: workPermit.workPermit.id});
    });

    return await api.post('/work-permits/update-work-permit-ranks', updatedWorkPermitRankings)
    .then(() => {
      this.setState({isLoading: false});
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;

    return function (a,b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      
      return result * sortOrder;
    }
  }

  returnCountryOptions = () => {
    const countryOptions = [];

    for (const country of this.state.countries) {
      countryOptions.push({
        label: (country.name),
        value: (country),
      });
    }

    return countryOptions;
  }

  cancelChanges = () => {
    this.setState({
      selectedRegion: null,
      blacklistedPermits: []
    })
  }

  handleEditClick = (permit) => {
    this.setState({
      isEditMode: true,
      selectedPermitRankObject: permit,
    });
  }
  handleCloseEdit = () => {
    this.setState({
      isEditMode: false,
      selectedPermitRankObject: null,
    })
  }
  resetRegion = () => {
    this.setState({
      selectedRegion: null,
      addManualAssessed: [],
      removeManualAssessed: [],
    });
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.selectedCompany &&
          <Header showClientNavigation ={true} showImmigrationNavigation={false} displayName={this.state.selectedCompany.name} link={"/select-company"}/>  
        }
        {this.state.isLoading ?
          <Loading />
        :
        <React.Fragment>
        {this.state.selectedCompany &&
          <div className="container position-relative">
            {
              this.state.isEditMode && this.state.selectedPermitRankObject &&
              <div className="order-permits__container bg-white">
                <WorkPermitOutputClient
                  selectedCountry={this.state.selectedCountry}
                  selectedRegion={this.state.selectedRegion}
                  selectedPermit={this.state.selectedPermitRankObject.workPermit}
                  handleClose={this.handleCloseEdit} 
                  manualAssessmentDetails={this.state.selectedPermitRankObject.manual}
                  />
              </div> 
            }
            <h3 className="text-secondary text-bold pb-4">Manage Permits</h3>
            <p className="text-danger font-weight-light pb-4">Please be aware newly created work permits for a country are automatically assigned the lowest rank in this section. This means it will be the last suggested permit to the client on their output unless rank is adjusted here. Please manage permits below according to client preference.</p>
            <p className="text-danger font-weight-light pb-4">
              <b>Important:</b> Disabling the Manually Assessed toggle for a permit will simultaneously delete any custom output text for the
              further assessment required results page.
            </p>
            <React.Fragment>
              {this.state.countries && !this.state.selectedRegion &&
              <div className="container d-flex justify-content-center">
                <div className="form-group map-select col-6">
                  <label htmlFor="country-select" className="text-secondary" >SELECT COUNTRY TO MANAGE PERMITS</label>
                    <Select 
                      options={this.returnCountryOptions()} 
                      onChange={(event) => this.onCountrySelected(event)} 
                    />
                </div>
              </div>
              }
              {this.state.selectedRegion &&
                <div className="d-flex w-100 justify-content-center">
                  <button className="btn btn-link mb-5" onClick={() => {this.resetRegion()}}>Reset Selected Country</button>
                </div>
                }
              {this.state.selectedRegion && this.state.workPermits &&
              <div>
                <div className="d-flex justify-content-center">
                  <table className="table w-75 text-center">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th className="text-left">Name</th>
                        <th>Adjust Rank</th>
                        <th>Enabled</th>
                        <th>Manually Assessed</th>
                        <th>Edit Output</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.workPermits.map((permit, index)=>
                        <tr className = {(permit.blacklist.status ? 'bg-light' : '')} key={index}>
                          <td>{index + 1}</td>
                          <td className="text-left">{permit.workPermit.name + (permit.workPermit.deloittePermitId && " - "+ permit.workPermit.deloittePermitId)}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                            {index !== 0 &&
                              <button onClick={() => this.onClickOrder(index, "up")} className="btn btn-primary btn-ranking mr-1"> &#8593;</button>
                            }
                            {index !== this.state.workPermits.length - 1 &&
                              <button onClick={() => this.onClickOrder(index, "down")} className="btn btn-primary btn-ranking mr-1"> &#8595;</button>
                            }
                              </div>
                          </td>
                          <td>
                            <BlueSwitch checked={!permit.blacklist.status} onChange={() => this.onClickBlacklistChange(permit)}/>
                          </td>
                          <td>
                            <BlueSwitch checked={permit.manual.status} onChange={() => this.updateManualStatus(permit)}/>
                          </td>
                          <td >
                            <EditOutlinedIcon className="order-permits__edit" onClick={() => this.handleEditClick(permit)}/>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {this.state.changesMade &&
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-secondary mr-2" onClick={this.saveChanges}>Save Changes</button>
                    <button className="btn btn-danger" onClick={this.cancelChanges}>Cancel</button>
                  </div>
                }
              </div>
              }
              </React.Fragment>
          </div>
          }
        </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default OrderPermits;