import React, { useContext } from 'react';
import {Link} from'react-router-dom';
import { CountryContext } from '../../context/CountryContext';

const CountryNavigationBar = () => {
  const [countryState] = useContext(CountryContext);

  const checkOnPage = (linkPath) => {
    const onPage = window.location.hash.includes(linkPath) ? true : false;
    return onPage;
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-around mt-4">
        <Link
          className="navigation-link" 
          to={`/countries/${countryState.selectedCountry ? countryState.selectedCountry.id : localStorage.getItem('countryId')}/requirements`}>
            <div className={"gw__navigation-text" + (checkOnPage("requirements") ? " gw__navigation-highlight": "")}>
              Country Requirements
            </div>
        </Link>
        <Link
          className="navigation-link"
          to={`/countries/${countryState.selectedCountry ? countryState.selectedCountry.id : localStorage.getItem('countryId')}/work-permits`}>
            <div className={"gw__navigation-text" + (checkOnPage("work-permits") ? " gw__navigation-highlight": "")}>
              Work Permits
            </div>
        </Link>
        <Link
          className="navigation-link"
          to={`/countries/${countryState.selectedCountry ? countryState.selectedCountry.id : localStorage.getItem('countryId')}/government-processing-times`}>
            <div className={"gw__navigation-text" + (checkOnPage("government") ? " gw__navigation-highlight": "")}>
              Government Processing Times
            </div>
        </Link>
        <Link
          className="navigation-link"
          to={`/countries/${countryState.selectedCountry ? countryState.selectedCountry.id : localStorage.getItem('countryId')}/citizenship-processing-times`}>
            <div className={"gw__navigation-text" + (checkOnPage("citizenship") ? " gw__navigation-highlight": "")}>
              Citizenship Processing Times
            </div>
        </Link>
        <Link
          className="navigation-link"
          to={`/countries/${countryState.selectedCountry ? countryState.selectedCountry.id : localStorage.getItem('countryId')}/output`}>
            <div className={"gw__navigation-text" + (checkOnPage("output") ? " gw__navigation-highlight": "")}>
              Work Permit Outputs
            </div>
        </Link>
        <Link
          className="navigation-link" 
          to={`/countries/${countryState.selectedCountry ? countryState.selectedCountry.id : localStorage.getItem('countryId')}/job-levels`}>
            <div className={"gw__navigation-text" + (checkOnPage("job-levels") ? " gw__navigation-highlight": "")}>
              Country Job Levels
            </div>
        </Link>
      </div>
      <hr className="mb-5 country-navigation-row gw__header-row"></hr>
    </React.Fragment>
  )
};
export default CountryNavigationBar;