import React, { Fragment, useContext } from 'react';
import { Link } from'react-router-dom';
import { CompanyContext } from '../../context/CompanyContext';

const CompanyNavigationBar = () => {
  const [companyState] = useContext(CompanyContext);

  const checkOnPage = (linkPath) => {
    const onPage = window.location.hash.includes(linkPath) ? true : false;
    return onPage;
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-around mt-4">
        <Link 
          className="navigation-link" 
          to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/job-positions/create`}
        >
          <div className={"gw__navigation-text" + (checkOnPage("job-positions/create") ? " gw__navigation-highlight": "")} >
            Job Positions
          </div>
        </Link>
        <Link
          className="navigation-link" 
          to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/job-positions/positions-to-job-codes`}
        >
          <div className={"gw__navigation-text" + (checkOnPage("job-positions/positions-to-job-codes") ? " gw__navigation-highlight": "")} >
            Map Job Positions to Job Codes
          </div>
        </Link>
        <Link 
          className="navigation-link" 
          to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/job-positions/positions-to-fields-of-study`}
        >
          <div className={"gw__navigation-text" + (checkOnPage("job-positions/positions-to-fields-of-study") ? " gw__navigation-highlight": "")} >
            Map Job Positions to Fields of Study
          </div>
        </Link>
	      <Link
		      className="navigation-link"
		      to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/job-levels/`}
	      >
		      <div className={"gw__navigation-text" + (checkOnPage("job-levels/") ? " gw__navigation-highlight": "")} >
			      Job Levels
		      </div>
	      </Link>
	      <Link
		      className="navigation-link"
		      to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/job-level-mappings/`}
	      >
		      <div className={"gw__navigation-text" + (checkOnPage("job-level-mappings/") ? " gw__navigation-highlight": "")} >
			      Map Job Level to Client Job Level
		      </div>
	      </Link>
        <Link 
          className="navigation-link" 
          to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/order-permits`}
        >
          <div className={"gw__navigation-text" + (checkOnPage("order-permits") ? " gw__navigation-highlight": "")} >
            Manage Work Permits
          </div>
        </Link>
        <Link 
            className="navigation-link" 
            to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/requirements`}
          >
          <div className={"gw__navigation-text" + (checkOnPage("requirements") ? " gw__navigation-highlight": "")} >
            Requirements
          </div>
        </Link>
        <Link 
          className="navigation-link" 
          to={`/companies/${companyState.selectedCompany ? companyState.selectedCompany.id : localStorage.getItem('companyId')}/announcements`}
        >
          <div className={"gw__navigation-text" + (checkOnPage("announcement") ? " gw__navigation-highlight": "")} >
            Announcements
          </div>
        </Link>
      </div>
      <hr className="mb-5 country-navigation-row gw__header-row"></hr>
    </Fragment>
  )
};

export default CompanyNavigationBar;
