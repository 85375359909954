import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { extendUserSession, logoutUser } from '../../services/UserService';

const SessionManager = () => {
  const [countdownTime, setCountdownTime] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const countdownTimeout = useRef(false);
  const checkInterval = useRef(false);

  // on each load, update the timeout with the current expiry time.
  useEffect(() => {
    startCheckInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return 0;
  }

  // start a 5 second interval to check the session expiry time.
  const startCheckInterval = () => {
    clearInterval(checkInterval.current);
    checkInterval.current = setInterval(() => {
      const cookies = document.cookie;
      if (cookies && cookies.split('expiryDate=')[1]) {
        const expiryDate = getCookie('expiryDate');
        const timeDif = expiryDate - new Date().getTime();

        if (timeDif < 65000 && timeDif > 5000) {
          setCountdownTime(Math.floor(timeDif / 1000));
          setShowModal(true);
          clearInterval(checkInterval.current);
        } else if(timeDif < 5000) {
          clearInterval(countdownTimeout.current);
          logoutUser();
        }
      }
    }, 5000);
  }

  // Start the countdown timer 
  React.useEffect(() => {
    clearTimeout(countdownTimeout.current);

    if (countdownTime && countdownTime > 0) {
      countdownTimeout.current = setTimeout(() => {
        setCountdownTime(countdownTime - 1);

        if (countdownTime === 1) {
          timeout();
        }
      }, 1000);
    }
    
    return () => clearTimeout(countdownTimeout.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime]);

  const timeout = () => {
    setShowModal(false);
    clearTimeout(countdownTimeout.current);
    logoutUser();
  }

  // reset the session cookies and reset timers.
  const resetSession = async () => {
    await extendUserSession();

    clearTimeout(countdownTimeout.current);
    setShowModal(false);
    startCheckInterval();
  }

  return (
    <Modal isOpen={showModal} centered>
      <ModalBody>
          <h2>Oh no! Your session is about to expire...</h2>
          <p>
            It looks like you've been away from the application for an extended period of time. For security purposes, you will be logged out in 
            <span className="font-weight-bold"> {countdownTime} seconds</span>
            , unless you wish to continue.
          </p>
      </ModalBody>
      <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={resetSession}>Continue Session</button>
      </ModalFooter>
    </Modal>
  )
};

export default SessionManager;
