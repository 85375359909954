import React, { Component } from 'react';
import {Collapse, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Select from 'react-select';
import {api} from '../../api';
import Header from '../../general/navigation/Header';
import RichTextEditor from '../../general/text-editor/RichTextEditor';
import Loading from '../../general/components/Loading.js';

export default class WorkPermitOutput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryId: localStorage.getItem('countryId'),
      isLoading: true,
      whitelistedUrls: [],
      blockedUrls: [],
      isUrlValid: true,
      isRemoveSectionModalOpen: false,
      regionId: localStorage.getItem('regionId'),
      sections: [],
      sectionToDeleteId: null,
      country: null,
      region: null,
      selectedWorkPermit: null,
      workPermits: [],
    };
  }

  componentDidMount() {
    if (!this.state.regionId || !this.state.countryId) {
      window.location.replace('/#/');
    } else{
      this.fetchCountryData();
      this.fetchWhitelistedUrls();
    }
  }

  async fetchWhitelistedUrls() {
    await api.get('/whitelist').then((res) => this.setState({ whitelistedUrls: res.data }));
  }

  async fetchCountryData() {
    const countriesResponse = await api.get(`/countries/${localStorage.getItem('countryId')}`);
    const region = countriesResponse.data.regions.find(regionToFind => regionToFind.id === parseInt(localStorage.getItem('regionId')));

    this.setState({
      country: countriesResponse.data,
      region
    }, () => this.fetchWorkPermitData());
  }

  async fetchWorkPermitData() {
    const workPermitsResponse = await api.get(`/countries/${localStorage.getItem('countryId')}/regions/${localStorage.getItem('regionId')}/work-permits`);
    const workPermitOptions = [];

    workPermitsResponse.data.forEach(workPermit => {
      workPermitOptions.push({
        value: workPermit,
        label: (workPermit.workPermitRevisions[0].name + (workPermit.workPermitRevisions[0].deloittePermitId && " - "+ workPermit.workPermitRevisions[0].deloittePermitId)),
      });
    });

    this.setState({
      isLoading: false,
      workPermits: workPermitOptions
    });
  }

  async fetchSections() {
    const recruiterOutputResponse = await api.get(`/work-permits/${this.state.selectedWorkPermit.id}/recruiter-output`);
    const sections = [];

    if (recruiterOutputResponse.data.sections) {
      recruiterOutputResponse.data.sections.forEach(section => {
        sections.push({
          id: section.id,
          staticTitle: section.title,
          title: section.title,
          content: section.content,
          isCollapseOpen: false,
          isSectionDirty: false,
        })
      });
    }

    this.setState({
      isLoading: false,
      sections,
      recruiterOutputId: recruiterOutputResponse.data.id,
    });
  }

  handleWorkPermitSelect = (event) => {
    this.setState({
      sections: [],
      selectedWorkPermit: event.value
    }, () => this.fetchSections());
  }

  toggleCollapse = (index) => {
    const {sections} = this.state;

    sections[index].isCollapseOpen = !sections[index].isCollapseOpen;
    this.setState({sections});
  }

  handleSectionTitle = (event, index) => {
    const {sections} = this.state;

    sections[index][event.target.name] = event.target.value;
    sections[index].isSectionDirty = true;
    this.setState({sections});
  }

  handleRichTextFormatInput = (section, editorText) => {
    const {sections} = this.state;
    let sectionToEdit = sections.find(stateSection => stateSection.content === section.content);
    sectionToEdit.content = editorText;
    sectionToEdit.isSectionDirty = true;
    this.isWorkPermitOutputValid()
    this.setState({sections});
  }

  addSection = () => {
    const {sections} = this.state;

    sections.push({
      staticTitle: 'Enter a new title below',
      title: '',
      content: '',
      isCollapseOpen: true,
      isSectionDirty: true,
    });

    this.setState({sections});
  }

  onClickSave = () => {
    this.setState({isLoading: true}, () => this.saveChanges())
  }

  async saveChanges() {
    for (const section of this.state.sections) {
      if (section.isSectionDirty) {
        const sectionToPost = {
          title: section.title,
          content: section.content
        };

        if (section.id) {
          await api.patch(`/work-permits/${this.state.selectedWorkPermit.id}/recruiter-output/${this.state.recruiterOutputId}/sections/${section.id}`, sectionToPost).then(() => {});
        } else {
          await api.post(`/work-permits/${this.state.selectedWorkPermit.id}/recruiter-output/${this.state.recruiterOutputId}/sections`, sectionToPost).then(() => {});
        }
      }
    }

    await this.fetchSections();
  }

  handleRemoveSectionClick = (section, index) => {
    if (section.id) {
      this.setState({
        sectionToDeleteId: section.id,
        isRemoveSectionModalOpen: true,
      });
    } else {
      const {sections} = this.state;
      sections.splice(index, 1);
      this.setState({sections});
    }
  }

  removeSection = () => {
    this.setState({isLoading: true}, () => {
      api.delete(`/work-permits/${this.state.selectedWorkPermit.id}/recruiter-output/${this.state.recruiterOutputId}/sections/${this.state.sectionToDeleteId}`)
        .then(() => {
          this.toggleModal();
          this.setState({sectionToDeleteId: null})
          this.fetchSections();
        });
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({
      isRemoveSectionModalOpen: !prevState.isRemoveSectionModalOpen,
    }));
  }

  isWorkPermitOutputValid() {
    if (this.state.sections.length === 0) {
      return false;
    }

    for (const section of this.state.sections) {
      if (section.isSectionDirty) {
        if ((!section.title || !section.content) ||
          (section.title.includes("<") || section.title.includes(">") ||
            section.content.includes("<") || section.content.includes(">"))) {
          return false;
        }

        if (section.content) {
          let parsedContent = JSON.parse(section.content);
          let hasSectionText = false;
          if (parsedContent.entityMap) {
            for (const [key] of Object.entries(parsedContent.entityMap)) {
              if (parsedContent.entityMap[key].data.url) {
                if (!this.state.whitelistedUrls.includes(parsedContent.entityMap[key].data.url)) {
                  this.setIsUrlValid(false);
                  if (!this.state.blockedUrls.includes(parsedContent.entityMap[key].data.url)) {
                    const blacklistedUrls = this.state.blockedUrls;
                    blacklistedUrls.push(parsedContent.entityMap[key].data.url);
                    this.setState({ blockedUrls: blacklistedUrls });
                  }
                  return false;
                }
              } else {
                this.setIsUrlValid(true);
              }
            }
          }
          for (const block of parsedContent.blocks) {
            if (block.text) {
              hasSectionText = true;
              break;
            }
          }
          return hasSectionText;
        }
      }
    }
    return true;
  }

  setIsUrlValid(valid) {
    if (this.state.isUrlValid !== valid) {
      this.setState({ isUrlValid: valid });
    }
  }

  render() {
    return (
      this.state.isLoading ?
        <Loading />
        :
        <React.Fragment>
          <Header showClientNavigation ={false} showImmigrationNavigation={true} displayName={this.state.country.name + (this.state.country.regions.length > 1 ? " - " + this.state.region.name : "")} link={"/select-country"}/>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="gw__section-headings">Work Permit Output</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6">
                <p className="">
                  In addition to the visa, work permit, stay duration and processing time range we will also supply the recruiter with the following information to aid
                  the candidate's transition to a new country.
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6">
                <label className="output-label">Work Permit</label>
                <Select options={this.state.workPermits} onChange={this.handleWorkPermitSelect} />
              </div>
            </div>
            {
              this.state.selectedWorkPermit && this.state.sections &&
              <React.Fragment>
                <div className="row mt-4">
                  {
                    this.state.sections.map((section, index) => {
                      return (
                        <div key={index} className="col-sm-12">
                          <hr className="mt-4" />
                          <div className="d-flex justify-content-between align-items-center" onClick={() => this.toggleCollapse(index)}>
                            <div className="output-section-header">{section.staticTitle}</div>
                            <div className="chevron"></div>
                          </div>
                          <Collapse className="mt-4 collapse-menu-item" isOpen={this.state.sections[index].isCollapseOpen}>
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              maxLength={255}
                              value={this.state.sections[index].title}
                              onChange={(event) => this.handleSectionTitle(event, index)}
                              placeholder="Please enter a title for the section"
                            />
                            <RichTextEditor section={section} handleRichTextFormatInput={this.handleRichTextFormatInput} />
                            {this.state.isUrlValid ?
                            !this.isWorkPermitOutputValid() && <p className="gw__invalid-name">{'INVALID WORK PERMIT OUTPUT (EITHER EMPTY OR INCLUDES "<" or ">" SYMBOLS)'}</p> 
                            : !this.isWorkPermitOutputValid() && <p className="gw__invalid-name">INVALID URL "{this.state.blockedUrls}" FOR THE WORK PERMIT OUTPUT</p>}
                            <button className="btn btn-danger mt-4" onClick={() => this.handleRemoveSectionClick(section, index)}>Remove Section</button>
                          </Collapse>
                        </div>
                      )
                    })
                  }
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <button type="button" className="btn btn-secondary" onClick={this.addSection}>Add Section</button>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <button disabled={!this.isWorkPermitOutputValid()} type="button" className="btn btn-primary" onClick={this.onClickSave}>Save Changes</button>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
          <Modal isOpen={this.state.isRemoveSectionModalOpen} toggle={this.toggleModal} className={this.props.className}>
            <ModalBody>
              Are you sure you want to remove this section?
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-secondary" onClick={this.toggleModal}>Close</button>
              <button type="button" className="btn btn-danger" onClick={this.removeSection}>Remove</button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
    )
  }
}
