import React from 'react';
import CountryNavigationBar from '../../country/nav-bar/CountryNavigationBar';
import CompanyNavigationBar from '../../client/nav-bar/CompanyNavigationBar';
import WapaNavigation from './WapaNavigation';


const Header = (props) => {
  const { displayName, link, showClientNavigation, showImmigrationNavigation } = props;

  return (
    <React.Fragment>
      <WapaNavigation displayName={displayName} link={link}/>
      {showImmigrationNavigation && <CountryNavigationBar />}
      {showClientNavigation && <CompanyNavigationBar />}
    </React.Fragment>
  )
}

export default Header;
