//Check if input is a valid JSON file
export default function jsonChecker(item){
    item = typeof item !== "string"
    ? JSON.stringify(item)
    : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}