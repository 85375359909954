import axios from 'axios';
import { logoutUser } from './services/UserService';

export const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://admin.wapa.deloitte:3030/' : '/api',
  withCredentials: true,
});

if (process.env.REACT_APP_SSO_ENABLED !== 'false') {
  api.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 403) {
        logoutUser();
      }
    }
  );
} else {
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 403) {
        window.location.replace('/#/login');
      }
    }
  );
}
