import {useEffect} from 'react';
import { useIsAuthenticated } from "@azure/msal-react";

const LoginHandler = () => {
  const isAuthenticated = useIsAuthenticated();

  const checkAuthentication = () => {
    if (isAuthenticated) {
      window.location.replace('/#/home');
    }
    else {
      window.location.replace('/#/login');
    }
  };

  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated);
    checkAuthentication();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  

  return null;
};

export default LoginHandler;
