import azure from '../azure';
import { PublicClientApplication} from '@azure/msal-browser';

let msalInstance = null;

export const msalConfig  = {
  auth: {
    clientId: azure.appId,
    authority: azure.ssoURL,
    redirectUri: azure.redirectUri,
    postLogoutRedirectUri: azure.redirectUri+"/#/login",
  },

  cache: {
    cacheLocation: "localStorage",
  },
};
export const loginRequest = {
  scopes: azure.scopes,
  //prompt: 'login',
};

export const setMsalInstance = (instance) => {
  msalInstance = instance;
};

export const initMsalInstance = () => {
  const instance = new PublicClientApplication(msalConfig);
  setMsalInstance(instance);
  return instance;
};
export const getMsalInstance = () => msalInstance;

