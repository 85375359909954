import { useEffect, Fragment } from 'react';
import { loginUser } from '../../services/UserService';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from '../../Config/msalConfig';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import { EventType } from '@azure/msal-browser';
const LoginPage = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        handleLogin(event.payload);
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        console.error("Login failed:", event.error);
      }
      else if (event.eventType === EventType.LOGOUT_SUCCESS || event.eventType === EventType.LOGOUT_FAILURE) {
        localStorage.removeItem('msalHomeAccountID');
        sessionStorage.removeItem('isloading');
      }
    });

    if (isAuthenticated) {
        navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle login redirect (this will be triggered when the user is redirected back)
  const handleLogin = async (response) => {
    try {
      const user = {
        account: {
          userName: response.account.username,
        }
      };
      await loginUser(user).then(() => {
        localStorage.setItem('msalHomeAccountID', response.account.homeAccountId);
        sessionStorage.removeItem('isloading');
        navigate('/home');
      }).catch((error) => {
        console.error("Token retrieval failed:", error);
      });
    }
    catch (error) {
      console.error("Redirect failed:", error);
    }
  }


  const login = async () => {
   
   

    if (process.env.REACT_APP_SSO_ENABLED !== "false" && !isAuthenticated) {
      sessionStorage.setItem('isloading', 'true');
      try {
        // Trigger MSAL login redirect
        await instance.loginRedirect(loginRequest);
      } catch (error) {
        console.error("Msal Login failed:", error);
      }
    }

    else if (process.env.REACT_APP_SSO_ENABLED === "false") {
      const user = {
        account: {
          userName: "userTest",
        },
      };
      await loginUser(user);
      navigate('/home');
    }
  }

  return (
    <Fragment>
      {sessionStorage.getItem('isloading') === 'true'
        ? <Loading />
        :
        <div>
          <div className="d-flex heading-container justify-content-center align-items-baseline">
            <div className="gw__nav_links d-flex">
              <h1 className="gw__nav-logo mb-0">
                <span className="gw__nav-title-go">Go</span>
                <span className="gw__nav-title-work">Work</span>
                <span className="gw__nav-title-dot">.</span>
              </h1>
              <div className="pl-3 mt-3 gw__nav-subtitle" >Immigration Hub</div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button className="btn btn-primary mt-5" onClick={login}>
              Sign In
            </button>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default LoginPage;
