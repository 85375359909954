import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';

import { api } from '../../api';
import Loading from '../../general/components/Loading.js';
import Header from '../../general/navigation/Header';
import { CountryContext } from '../../context/CountryContext';
import {
  fetchCountry,
  fetchCountryJobLevels,
  fetchCountryJobCodes,
  fetchCountryRequirements,
  createSalaryRange,
  fetchSalaryRanges,
  deleteSalaryRanges,
  fetchWorkPermit,
} from '../../services/CountryService';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import CountryMappingSelect from './CountryMappingSelect.js';

const AddWorkPermit = () => {
  const countryState = useContext(CountryContext);
  const { id: workPermitId, countryId } = useParams();
  // General data
  const [regionId] = useState(localStorage.getItem('regionId'));
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [pastedString] = useState('');

  // Country related data
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [workPermitCitizenshipDropdown, setWorkPermitCitizenshipDropdown] = useState([]);
  const [countryRequirements, setCountryRequirements] = useState([]);
  const [countryJobCodes, seCountryJobCodes] = useState([]);
  const [countryJobLevels, setCountryJobLevels] = useState([]);
  const [workPermits, setWorkPermits] = useState([]);

  // Work permit data
  const [workPermitData, setWorkPermitData] = useState(null);
  const [name, setName] = useState('');
  const [deloittePermitId, setDeloittePermitId] = useState('');
  const [description, setDescription] = useState('');
  const [processingTime, setProcessingTime] = useState(0);
  const [premiumProcessingTime, setPremiumProcessingTime] = useState(0);
  const [pointsRequired, setPointsRequired] = useState(null);
  const [deloitteProcessingTime, setDeloitteProcessingTime] = useState(0);
  const [visaProcessingTime, setVisaProcessingTime] = useState(0);
  const [visaPremiumProcessingTime, setVisaPremiumProcessingTime] = useState(0);
  const [inCountryProcessingTime, setInCountryProcessingTime] = useState(0);
  const [permanentResidency, setPermanentResidency] = useState(null);
  const [validity, setValidity] = useState(0);
  const [validitySelection, setValiditySelection] = useState(0);

  const [isSensitiveToFieldOfStudy, setIsSensitiveToFieldOfStudy] = useState({ label: 'No', value: false });
  const [isOnlyForExistingVisaHolders, setIsOnlyForExistingVisaHolders] = useState({ label: 'No', value: false });
  const [isCitizenshipSpecific, setIsCitizenshipSpecific] = useState({ label: 'No', value: false });

  // IDs of the selected values to submit to the backend for mapping.
  const [selectedWorkPermitCitizenships, setSelectedWorkPermitCitizenships] = useState([]);
  const [selectedWorkPermitSourceRegions, setSelectedWorkPermitSourceRegions] = useState([]);
  const [selectedJobCodes, setSelectedJobCodes] = useState([]);
  const [selectedJobLevels, setSelectedJobLevels] = useState([]);
  const [requirements, setRequirements] = useState([]);

  // The default values to be shown on the react select. This gets assigned based on whether the work permit data is found in the DB.
  const [citizenshipValues, setCitizenshipValues] = useState([]);
  const [jobCodeValues, setJobCodeValues] = useState([]);
  const [jobLevelValues, setJobLevelValues] = useState([]);
  const [requirementValues, setRequirementValues] = useState([]);

  const [salaryThresholds, setSalaryThresholds] = useState([{min: null, max: null, workPermit: parseInt(workPermitId) }]);
  const [salaryThresholdsToDelete, setSalaryThresholdsToDelete] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let allCountries = [];
      if (countryState[0].countries.length > 0) {
        allCountries = countryState[0].countries;
      } else {
        const allCountriesResponse = await api.get(`/countries`);
        allCountries = allCountriesResponse.data;
      }

      // Fetch default data
      const countryResponse = await fetchCountry(countryId);
      const countryJobLevels = await fetchCountryJobLevels(countryId);
      const countryJobCodes = await fetchCountryJobCodes(countryId);
      const countryRequirements = await fetchCountryRequirements(countryId, regionId);

      // Builds the dropdown.
      buildJobCodesDropdown(countryJobCodes);
      buildJobLevelsDropdown(countryJobLevels);
      buildWorkPermitCitizenshipDropdown(allCountries);
      buildRequirementsValues(countryRequirements);

      if (workPermitId) {
        // Fetch configured data
        setIsEditing(true);
        const workPermitResponse = await fetchWorkPermit(workPermitId);
        setWorkPermitData(workPermitResponse.data);

        // Fetch and set the salary ranges in the database.
        const salaryRangesResponse = await fetchSalaryRanges(workPermitId);

        if (salaryRangesResponse.length > 0) {
          setSalaryThresholds(salaryRangesResponse);
        }

        // Sets the selected data that has been mapped to the work permit.
        buildPreSelectedWorkPermitCitizenships(workPermitResponse.data.workPermitCitizenships, allCountries);
        buildPreSelectedJobCodes(workPermitResponse.data.workPermitJobCodes, countryJobCodes);
        buildPreSelectedJobLevels(workPermitResponse.data.workPermitJobLevels, countryJobLevels);
        buildCurrentRequirementValues(workPermitResponse.data.workPermitRequirements, countryRequirements);

        // Sets the basic work permit data from workPermitRevisions.
        buildExistingWorkPermit(workPermitResponse.data);
      } else {
        buildCurrentRequirementValues(null, countryRequirements);
      }

      let initialRegion = null;
      initialRegion = countryResponse.regions.filter(item => item.id.toString() === regionId);
      setCountry(countryResponse);
      setCountryRequirements(countryRequirements);
      setRegion(initialRegion[0]);

      if (initialRegion[0]) {
        const workPermitsResponse = await api.get(`countries/${countryResponse.id}/regions/${initialRegion[0].id}/work-permits`);
        setWorkPermits(workPermitsResponse.data);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Pre-load the values array for react select with citizenships obtained from the database.
   * And also the IDs so that the ID array is accurate with what is shown on screen.
   * @param {*} selectedWorkPermitCitizenships
   * @param {*} allCountries
   */
  const buildPreSelectedWorkPermitCitizenships = (selectedWorkPermitCitizenships, allCountries) => {
    const preselectedWorkPermitCitizenships = [];
    const selectedCitizenshipIds = [];

    if (selectedWorkPermitCitizenships) {
      selectedWorkPermitCitizenships.forEach(workPermitCitizenship => {
        if (workPermitCitizenship.country) {
          let foundWorkPermitCitizenship = allCountries.filter(country => country.id === workPermitCitizenship.country.id)[0];

          if (foundWorkPermitCitizenship) {
            preselectedWorkPermitCitizenships.push({
              label: foundWorkPermitCitizenship.name,
              value: foundWorkPermitCitizenship.id,
            });
            selectedCitizenshipIds.push(workPermitId ? foundWorkPermitCitizenship : foundWorkPermitCitizenship.id);
          }
        } else {
          preselectedWorkPermitCitizenships.push({
            label: 'Not disclosed',
            value: 'Not disclosed',
          });
          selectedCitizenshipIds.push(workPermitId ? 'Not disclosed' : null); // Push the null value for 'Not disclosed'
        }
      });
    }

    setCitizenshipValues(preselectedWorkPermitCitizenships);
    setSelectedWorkPermitCitizenships(selectedCitizenshipIds);
  };

  /**
   * Build the array containing job code values for react select that have been pulled from the database.
   * @param {*} selectedWorkPermitJobCodes
   * @param {*} countryJobCodes
   */
  const buildPreSelectedJobCodes = (selectedWorkPermitJobCodes, countryJobCodes) => {
    const responseSelectedJobCodes = [];
    const selectedJobCodeIds = [];

    if (selectedWorkPermitJobCodes) {
      selectedWorkPermitJobCodes.forEach(workPermitJobCode => {
        let result = countryJobCodes.find(jobCode => jobCode.id === workPermitJobCode.jobCode.id);

        if (result) {
          responseSelectedJobCodes.push({
            label: result.name,
            value: result.id,
          });
          selectedJobCodeIds.push(result.id);
        }
      });
    }

    setSelectedJobCodes(selectedJobCodeIds);
    setJobCodeValues(responseSelectedJobCodes);
  };

  /**
   * Pulls the job level mapping data from the database and assigns the already selected levels to the values array,
   * also builds the array of selected job level IDs to match.
   * @param {*} selectedWorkPermitJobLevels
   * @param {*} countryJobLevels
   */
  const buildPreSelectedJobLevels = (selectedWorkPermitJobLevels, countryJobLevels) => {
    const responseSelectedJobLevels = [];
    const selectedJobLevelIds = []

    if (selectedWorkPermitJobLevels) {
      selectedWorkPermitJobLevels.forEach(workPermitJobLevel => {
        let result = countryJobLevels.find(jobLevel => jobLevel.id === workPermitJobLevel.jobLevel.id);

        if (result) {
          responseSelectedJobLevels.push({
            label: result.name,
            value: result.id,
          });
          selectedJobLevelIds.push(result.id);
        }
      });
    }

    setSelectedJobLevels(selectedJobLevelIds);
    setJobLevelValues(responseSelectedJobLevels);
  };

  const buildJobCodesDropdown = (countryJobCodes) => {
    let jobCodes = [];

    countryJobCodes.forEach(jobCode => {
      jobCodes.push({
        label: jobCode.name,
        value: jobCode.id
      })
    });

    seCountryJobCodes(jobCodes);
  };

  const buildJobLevelsDropdown = (countryJobLevels) => {
    let jobLevels = [];

    countryJobLevels.forEach(jobLevel => {
      jobLevels.push({
        label: jobLevel.name,
        value: jobLevel.id
      })
    });

    setCountryJobLevels(jobLevels);
  };

  const buildWorkPermitCitizenshipDropdown = (countries) => {
    let workPermitCitizenships = [{
      label: 'Not disclosed',
      value: 'Not disclosed',
    }];

    countries.forEach(country => {
      workPermitCitizenships.push({
        label: country.name,
        value: country.id,
      });
    });

    setWorkPermitCitizenshipDropdown(workPermitCitizenships);
  };

  /**
   * Builds the requirement values dropdown according to each configured requirement for the country.
   * @param {*} countryRequirements
   */
  const buildRequirementsValues = (countryRequirements) => {
    let updatedRequirements = [...requirements];
    countryRequirements.forEach(requirement => {
      updatedRequirements.push({
        id: requirement.id,
        values: [],
      });
    })
    setRequirements(updatedRequirements);
  };

  /**
   * Pull and assign the requirements which have already been selected from the database.
   * @param {*} workPermitRequirementValues
   */
  const buildCurrentRequirementValues = (workPermitRequirementValues, countryRequirements) => {
    let defaultRequirementValues = [];
    countryRequirements.forEach(requirement => {
      defaultRequirementValues.push({
        id: requirement.id,
        values: [],
      });
    });

    // If there is data pulled from the database sets the array of requirement values to show in react select.
    // Populates the array as {label, value} and also adds the pulled data to the IDs array to be submitted to the backend.
    if (workPermitRequirementValues) {
      let requirementsValues = [];
      let requirementValueIds = [];

      const groupBy = key => array =>
        array.reduce((objectsByKeyValue, obj) => {
          const value = obj[key];
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
          return objectsByKeyValue;
        }, {});

      workPermitRequirementValues.forEach(requirementValue => {
        requirementsValues.push({
          id: requirementValue.requirement.id,
          value: requirementValue.value.id,
          label: requirementValue.value.requirementValueRevisions[0].value
        });
        requirementValueIds.push(workPermitId ? {id: requirementValue.value.id} : requirementValue.value.id); // Check if this line is even needed.
      });

      const groupByRequirementId = groupBy('id');
      requirementsValues = groupByRequirementId(requirementsValues);

      defaultRequirementValues.forEach(requirement => {
        if (requirementsValues[requirement.id]) {
          requirementsValues[requirement.id].forEach(currentValue => {
            requirement.values.push({value: currentValue.value, label: currentValue.label});
          });
        }
      });
    }

    setRequirementValues(defaultRequirementValues);
  };

  /**
   * Set the work permit information that was pulled from the database.
   * @param {Object} workPermitResponseData
   */
  const buildExistingWorkPermit = (workPermitResponseData) => {
    const workPermitRevision = workPermitResponseData.workPermitRevisions[0];

    // General Work Permit info
    setName(workPermitRevision.name);
    setDeloittePermitId(workPermitRevision.deloittePermitId);
    setDescription(workPermitRevision.description);

    // Processing Times
    setDeloitteProcessingTime(workPermitRevision.deloitteProcessingTime);
    setProcessingTime(workPermitRevision.processingTime);
    setPremiumProcessingTime(workPermitRevision.premiumProcessingTime);
    setVisaProcessingTime(workPermitRevision.visaProcessingTime);
    setVisaPremiumProcessingTime(workPermitRevision.visaPremiumProcessingTime);
    setInCountryProcessingTime(workPermitRevision.inCountryProcessingTime);

    // Other Work Permit Values
    setIsSensitiveToFieldOfStudy(workPermitRevision.isSensitiveToFieldOfStudy === 1 ? {label: 'Yes', value: true} :  {label: 'No', value: false});
    setIsOnlyForExistingVisaHolders(workPermitRevision.isOnlyForExistingVisaHolders === 1 ? {label: 'Yes', value: true} :  {label: 'No', value: false});
    setIsCitizenshipSpecific(workPermitRevision.isCitizenshipSpecific === 1 ? {label: 'Yes', value: true} :  {label: 'No', value: false});
    setPermanentResidency(workPermitRevision.permanentResidency.toString());

    if (workPermitRevision.validity >= 1) {
      setValidity(workPermitRevision.validity);
      setValiditySelection(1);
    } else {
      setValiditySelection(workPermitRevision.validity);
    }
  };

  const handleInput = (event) => {
    const updateValues = {
      deloittePermitId: setDeloittePermitId,
      deloitteProcessingTime: setDeloitteProcessingTime,
      description: setDescription,
      name: setName,
      permanentResidency: setPermanentResidency,
      pointsRequired: setPointsRequired,
      premiumProcessingTime: setPremiumProcessingTime,
      processingTime: setProcessingTime,
      validity: setValidity,
      validitySelection: setValiditySelection,
      visaPremiumProcessingTime: setVisaPremiumProcessingTime,
      visaProcessingTime: setVisaProcessingTime,
      inCountryProcessingTime: setInCountryProcessingTime,
    }

    updateValues[event.target.name] && updateValues[event.target.name](event.target.value)
  };

  const handleInputNumbers = (event) => {
    const updateValues = {
      deloitteProcessingTime: setDeloitteProcessingTime,
      processingTime: setProcessingTime,
      premiumProcessingTime: setPremiumProcessingTime,
      visaProcessingTime: setVisaProcessingTime,
      visaPremiumProcessingTime: setVisaPremiumProcessingTime,
      inCountryProcessingTime: setInCountryProcessingTime,
      validity: setValidity,
    }

    const num = parseInt(event.target.value);

    updateValues[event.target.name] && updateValues[event.target.name](!isNaN(num) ? num : '')

  };

  const handleJobSelection = (event) => {
    let jobCodes = [];
    let selectedJobCodeValues = [];

    if (event) {
      event.forEach(e => {
        jobCodes.push(e.value);
        selectedJobCodeValues.push({
          label: e.label,
          value: e.value,
        });
      });
    }

    setSelectedJobCodes(jobCodes);
    setJobCodeValues(selectedJobCodeValues);
  };

  const handleJoblevelSelection = (event) => {
    let jobLevels = [];
    const selectedJobLevelValues = [];

    if (event) {
      event.forEach(e => {
        jobLevels.push(e.value);
        selectedJobLevelValues.push({
          label: e.label,
          value: e.value,
        });
      });
    }

    setSelectedJobLevels(jobLevels);
    setJobLevelValues(selectedJobLevelValues);
  };

  const handleWorkPermitCitizenshipSelection = async (event) => {
    const allCountriesResponse = await api.get(`/countries`);
    const allCountries = allCountriesResponse.data;
    let workPermitCitizenships = [];
    let selectedCitizenshipValues = [];

    if (event) {
      event.forEach(e => {
        const countryObject = allCountries.filter(country => country.id === e.value)[0];
        selectedCitizenshipValues.push({
          label: e.label,
          value: e.value,
        });

        if (e.value === 'Not disclosed') {
          workPermitCitizenships.push(workPermitId ? e.value : null);
        } else {
          workPermitCitizenships.push(workPermitId ? countryObject : e.value);
        }
      });
    }
    setCitizenshipValues(selectedCitizenshipValues);
    setSelectedWorkPermitCitizenships(workPermitCitizenships);
  };

  /**
   * Function to convert pasted citizenships into citizenship values.
   * However, will cause the typing/search functionality in react select to not work.
   * @param {String} pastedString
   */
  const handleCitizenshipPaste = async (pastedString) => {
    const allCountriesResponse = await api.get(`/countries`);
    let citizenshipsToBeSubmitted = [];

    if (pastedString.indexOf(';') > 0) {
      const valuesToSet = citizenshipValues;
      let splitCitizenshipArray = pastedString.split(';');

      for (const citizenship of splitCitizenshipArray) {
        const valueToAdd = workPermitCitizenshipDropdown.find(c => c.label.toLowerCase().trim() === citizenship.toLowerCase().trim());

        if (valueToAdd) {
          valuesToSet.push(valueToAdd);
        }
      }

      for (const citizenship of valuesToSet) {
        const allCountries = allCountriesResponse.data;
        let country = allCountries.filter(c => c.id === citizenship.value)[0];

        if (country) {
          citizenshipsToBeSubmitted.push(country);
        }
      }
      setSelectedWorkPermitCitizenships(citizenshipsToBeSubmitted);
    }
  };

  const buildValuesDropdown = (values) => {
    let valuesOptions = [];

    values.forEach(value => {
      valuesOptions.push({
        label: value.requirementValueRevisions[0].value,
        value: value.id
      });
    });

    return valuesOptions;
  };

  /**
   * Add the selected requirementValues to the value array and also the requirements array to submit to the
   * backend.
   * @param {*} event
   * @param {*} requirementId
   */
  const handleRequirementValueMultiSelection = (event, requirementId) => {
    let valuesArray = [];
    let selectedValues = [];

    // Get the relevant requirement
    let copyRequirements = requirements.slice();
    let foundRequirement = copyRequirements.find(req => req.id === requirementId);

    let tempRequirements = requirementValues.slice();
    let stateRequirementValues = tempRequirements.find(req => req.id === requirementId);

    if (event) {
      event.forEach(e => {
        valuesArray.push(isEditing ? {id: e.value} : e.value);

        selectedValues.push({
          label: e.label,
          value: e.value,
        });
      });
    }

    foundRequirement.values = valuesArray; // to be checked
    stateRequirementValues.values = selectedValues;

    setRequirements(copyRequirements);  // to be checked
    setRequirementValues(tempRequirements);
  };

  const addSalaryRange = () => {
    const tempSalaryRanges = salaryThresholds.slice();
    tempSalaryRanges.push({min: '', max: '', workPermit: workPermitId});
    setSalaryThresholds(tempSalaryRanges);
  };

  const handleSalaryInput = (event, index) => {
    const tempSalaryThresholds = salaryThresholds.slice();
    if (event.target.name === 'minSalary') {
      tempSalaryThresholds[index].min = event.target.value;
    } else if (event.target.name === 'maxSalary') {
      tempSalaryThresholds[index].max = event.target.value;
    }
    setSalaryThresholds(tempSalaryThresholds);
  };

  const isDeloitteIdUnique = (deloitteId) => {
    let unique = true;
    if (deloitteId) {
      for (const workPermit of workPermits) {
        if (workPermit.workPermitRevisions[0].deloittePermitId
          && workPermitId
          && (workPermit.id.toString() !== workPermitId.toString())) {
          if (workPermit.workPermitRevisions[0].deloittePermitId.replace(/\s/g, '') === deloitteId.replace(/\s/g, '')) {
            unique = false;
          }
        }
      }
    }
    return unique;
  };

  const isFormValid = () => {
    if (!name || !description || description.includes("<") || description.includes(">") || name.includes("<") || name.includes(">")) {
      return false;
    }

    for (const salaryThreshold of salaryThresholds) {
      // Form is invalid if max salary is configured without min.
      if (salaryThreshold.max !== null && salaryThreshold.min === null) {
        return false;
      }
    }

    if (parseInt(visaProcessingTime) < 0 || typeof visaProcessingTime !== 'number') {
      return false;
    }

    if (parseInt(deloitteProcessingTime) < 0 || typeof deloitteProcessingTime !== 'number') {
      return false;
    }

    if (parseInt(processingTime) < 0 || typeof processingTime !== 'number') {
      return false;
    }

    if (parseInt(premiumProcessingTime) < 0 || typeof premiumProcessingTime !== 'number') {
      return false;
    }

    if (parseInt(visaPremiumProcessingTime) < 0 || typeof visaPremiumProcessingTime !== 'number') {
      return false;
    }

    if (parseInt(inCountryProcessingTime) < 0 || typeof inCountryProcessingTime !== 'number') {
      return false;
    }

    if ((parseInt(validitySelection, 10) === 1 && validity < 1) || typeof validity !== 'number') {
      return false;
    }

    if (permanentResidency === null) {
      return false;
    }

    for (const requirement of requirementValues) {
      const req = countryRequirements.filter(r => r.id === requirement.id)[0];

      if (req && (req.type !== 'fieldOfStudy') && (req.type !== 'jobLevel') && (req.type !== 'salaryThreshold') && (req.type !== 'clientSpecificQuestion')) {
        if (!requirement.values) {
          return false;
        }

        if (requirement.values.length < 1) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * Create the work permit data to be submitted to the backend.
   * Without requirements - this differs depending on whether creating/updating work permits.
   */
  const createWorkPermitObject = () => {
    let workPermitObject = {
      name,
      description,
      deloittePermitId,
      processingTime,
      premiumProcessingTime,
      permanentResidency,
      validity: parseInt(validitySelection) === 1 ? validity : validitySelection,
      deloitteProcessingTime,
      visaProcessingTime,
      visaPremiumProcessingTime,
      inCountryProcessingTime,
      isSensitiveToFieldOfStudy: isSensitiveToFieldOfStudy.value,
      isOnlyForExistingVisaHolders: isOnlyForExistingVisaHolders.value,
      isCitizenshipSpecific: isCitizenshipSpecific.value,
      pointsRequired,
      region: region.id,
      jobCodes: selectedJobCodes,
      jobLevels: selectedJobLevels,
      workPermitCitizenships: selectedWorkPermitCitizenships,
      workPermitSourceRegions: selectedWorkPermitSourceRegions,
    };
    return workPermitObject;
  };

  const createWorkPermit = () => {
    setIsLoading(true);

    if (!isDeloitteIdUnique(deloittePermitId)) {
      alert("Deloitte Permit ID already exists for a permit under this country, please rename to continue");
      setIsLoading(false);
    } else {
      let workPermitToBeCreated = createWorkPermitObject();
      workPermitToBeCreated.requirements = requirements;

      api
        .post(`/work-permits`, workPermitToBeCreated)
        .then(response => {
          const tempSalary = salaryThresholds.slice();
          for (const salaryThreshold of tempSalary) {
            salaryThreshold.workPermit = parseInt(response.data.id);
          }
          setSalaryThresholds(tempSalary);
          updateSalaryRanges();

          window.location.replace(`/#/countries/${country.id}/work-permits`);
        })
        .catch(error => {
          // TODO: Error handling with toast
          setIsLoading(false);
        });
    }
  };

  /**
   * Take the current data and update the work permit in database.
   */
  const updateWorkPermit = () => {
    setIsLoading(true);

    if(!isDeloitteIdUnique(deloittePermitId)){
      alert("Deloitte Permit ID already exists for a permit under this country, please rename to continue");
      setIsLoading(false);
    } else {
      let workPermitToBeUpdated = createWorkPermitObject();

      // When updating requirements, the backend only accepts data in the format: {id: requirementId, values: {id: requirementId, ...}}
      let requirementValueToSubmit = [];
      requirementValues.forEach(requirement => {
        const valuesToPush = []
        requirement.values.forEach(requirementValue => {
          valuesToPush.push({id: requirementValue.value});
        });
        requirementValueToSubmit.push({id: requirement.id, values: valuesToPush});
      })
      workPermitToBeUpdated.requirements = requirementValueToSubmit;

      api.patch(`/work-permits/${workPermitId}`, workPermitToBeUpdated)
        .then(() => {
          updateSalaryRanges();
          deleteSalaryRanges(salaryThresholdsToDelete);
          // setIsLoading(false);
          window.location.replace(`/#/countries/${country.id}/work-permits`);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const updateSalaryRanges = () => {
    const salaryReq = countryRequirements.filter(r => r.type === 'salaryThreshold')[0];
    const tempSalaryRanges = salaryThresholds.slice();
    for (const salaryRange of tempSalaryRanges) {
      if (salaryRange.max === '') {
        salaryRange.max = null;
      }
    }

    if (salaryReq) {
      createSalaryRange(tempSalaryRanges);
    }
  };

  const removeSalaryRange = (index, salaryRange) => {
    const salaryRequirement = countryRequirements.find(requirement => requirement.type === 'salaryThreshold');
    const tempRangeToBeDeleted = salaryThresholdsToDelete.slice();
    salaryRange.requirementId = salaryRequirement.id;

    if (workPermitId) {
      salaryRange.workPermit = parseInt(workPermitId);
    }

    const tempSalaryThresholds = salaryThresholds.slice();
    tempSalaryThresholds.splice(index, 1);

    tempRangeToBeDeleted.push(salaryRange);

    setSalaryThresholdsToDelete(tempRangeToBeDeleted);
    setSalaryThresholds(tempSalaryThresholds);
  };

  const handleFieldOfStudyFilterSelection = event => {
    setIsSensitiveToFieldOfStudy(event);
  }

  const handleExistingVisaHolders = event => {
    setIsOnlyForExistingVisaHolders(event);
  }

  const handleCitizenshipSpecificity = event => {
    setIsCitizenshipSpecific(event);
  }

  return (
    <React.Fragment>
      {country &&
      <React.Fragment>
        { isLoading
          ? <Loading />
          : <div>
          <Header
            showClientNavigation={false}
            showImmigrationNavigation={true}
            displayName={country.name + (country.regions.length > 1 ? ' - ' + region.name : '')} link={"/select-country"}
          />
          <div className="container">
              {!isEditing
               ? <h3 className="mt-3 gw__section-headings">New Work Permit</h3>
               : <h3 className="mt-3 gw__section-headings">Edit Work Permit</h3>
              }
              <form>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="name">Name</label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        maxLength={255}
                        value={name}
                        onChange={handleInput}
                        placeholder="Enter work permit name"
                      />
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="deloittePermitId">Deloitte Permit ID</label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        className="form-control"
                        type="text"
                        name="deloittePermitId"
                        value={deloittePermitId}
                        maxLength={255}
                        onChange={handleInput}
                        placeholder="Enter Deloitte Permit ID"
                      />
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="description">Description</label>
                    </div>
                    <div className="col-sm-6">
                      <TextareaAutosize
                        className="form-control text-area-sizing"
                        type="text"
                        name="description"
                        value={description}
                        onChange={handleInput}
                        placeholder="Enter work permit description"
                        onKeyPress={e => {
                          if(e.key === 'Enter')
                             e.preventDefault()
                        }} // Don't allow enter key it's not stored in the DB.
                      />
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Deloitte Processing Time (in weeks)</label>
                    </div>
                    <div className="col-sm-6 d-flex">
                      <RemoveCircleOutlineOutlinedIcon
                        className="gw__number-arrows left"
                        onClick={() => {setDeloitteProcessingTime(parseInt(deloitteProcessingTime)-1)}}
                      />
                      <input
                        className="form-control"
                        type="number"
                        name="deloitteProcessingTime"
                        min={0}
                        value={deloitteProcessingTime}
                        onChange={handleInputNumbers}
                        // placeholder="Enter Deloitte processing time"
                      />
                      <AddCircleOutlineOutlinedIcon
                        className="gw__number-arrows right"
                        onClick={() => {setDeloitteProcessingTime(parseInt(deloitteProcessingTime)+1)}}
                      />
                      {(deloitteProcessingTime < 0 || deloitteProcessingTime.length === 0) && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Processing Time (in weeks)</label>
                    </div>
                    <div className="col-sm-6 d-flex">
                      <RemoveCircleOutlineOutlinedIcon
                        className="gw__number-arrows left"
                        onClick={() => {setProcessingTime(parseInt(processingTime)-1)}}
                      />
                      <input
                        className="form-control"
                        type="number"
                        name="processingTime"
                        min={0}
                        value={processingTime}
                        onChange={handleInputNumbers}
                        // placeholder="Enter processing time"
                      />
                      <AddCircleOutlineOutlinedIcon
                        className="gw__number-arrows right"
                        onClick={() => {setProcessingTime(parseInt(processingTime)+1)}}
                      />
                      {(processingTime < 0 || processingTime.length === 0) && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Premium Processing Time (in weeks)</label>
                    </div>
                    <div className="col-sm-6 d-flex">
                      <RemoveCircleOutlineOutlinedIcon
                        className="gw__number-arrows left"
                        onClick={() => {setPremiumProcessingTime(parseInt(premiumProcessingTime)-1)}}
                      />
                      <input
                        className="form-control"
                        type="number"
                        name="premiumProcessingTime"
                        min={0}
                        value={premiumProcessingTime}
                        onChange={handleInputNumbers}
                        // placeholder="Enter premium processing time"
                      />
                      <AddCircleOutlineOutlinedIcon
                        className="gw__number-arrows right"
                        onClick={() => {setPremiumProcessingTime(parseInt(premiumProcessingTime)+1)}}
                      />
                      {(premiumProcessingTime < 0 || premiumProcessingTime.length === 0) && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Visa Processing Time (in weeks)</label>
                    </div>
                    <div className="col-sm-6 d-flex">
                      <RemoveCircleOutlineOutlinedIcon
                        className="gw__number-arrows left"
                        onClick={() => {setVisaProcessingTime(parseInt(visaProcessingTime)-1)}}
                      />
                      <input
                        className="form-control"
                        type="number"
                        name="visaProcessingTime"
                        min={0}
                        value={visaProcessingTime}
                        onChange={handleInputNumbers}
                        // placeholder="Enter visa processing time"
                      />
                      <AddCircleOutlineOutlinedIcon
                        className="gw__number-arrows right"
                        onClick={() => {setVisaProcessingTime(parseInt(visaProcessingTime)+1)}}
                      />
                      {(visaProcessingTime < 0 || visaProcessingTime.length === 0) && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Visa Premium Processing Time (in weeks)</label>
                    </div>
                    <div className="col-sm-6 d-flex">
                      <RemoveCircleOutlineOutlinedIcon
                        className="gw__number-arrows left"
                        onClick={() => {setVisaPremiumProcessingTime(parseInt(visaPremiumProcessingTime)-1)}}
                      />
                      <input
                        className="form-control"
                        type="number"
                        name="visaPremiumProcessingTime"
                        min={0}
                        value={visaPremiumProcessingTime}
                        onChange={handleInputNumbers}
                        // placeholder="Enter visa premium processing time"
                      />
                      <AddCircleOutlineOutlinedIcon
                        className="gw__number-arrows right"
                        onClick={() => {setVisaPremiumProcessingTime(parseInt(visaPremiumProcessingTime)+1)}}
                      />
                      {(visaPremiumProcessingTime < 0 || visaPremiumProcessingTime.length === 0) && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                    </div>
                  </div>
                </div>
                {/* Add In Country Processing Time */}
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">In Country Processing Time (in weeks)</label>
                    </div>
                    <div className="col-sm-6 d-flex">
                      <RemoveCircleOutlineOutlinedIcon
                        className="gw__number-arrows left"
                        onClick={() => {setInCountryProcessingTime(parseInt(inCountryProcessingTime)-1)}}
                      />
                      <input
                        className="form-control"
                        type="number"
                        name="inCountryProcessingTime"
                        min={0}
                        value={inCountryProcessingTime}
                        onChange={handleInputNumbers}
                        // placeholder="Enter visa premium processing time"
                      />
                      <AddCircleOutlineOutlinedIcon
                        className="gw__number-arrows right"
                        onClick={() => {setInCountryProcessingTime(parseInt(inCountryProcessingTime)+1)}}
                      />
                      {(inCountryProcessingTime < 0 || inCountryProcessingTime.length === 0) && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                    </div>
                  </div>
                </div>
                {
                  countryRequirements.filter(req => req.type === 'fieldOfStudy')[0] &&
                  <div className="formGroup mt-5">
                    <div className="row">
                      <div className="col-sm-6 align-self-center">
                        <label className="gw__form-sections" htmlFor="type">Does role to field of study filter apply?</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          // className="gw__select-dropdown"
                          onChange={handleFieldOfStudyFilterSelection}
                          value={isSensitiveToFieldOfStudy}
                          options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Should this work permit only be for existing visa holders?</label>
                    </div>
                    <div className="col-sm-6">
                      <Select
                        onChange={handleExistingVisaHolders}
                        value={isOnlyForExistingVisaHolders}
                        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                      />
                    </div>
                  </div>
                </div>

                {/* The dropdown for whether this work permit should be shown on the ineligible if candidate doesn't meet citizenship requirement */}
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Is this work permit reserved for specific citizenships?</label>
                    </div>
                    <div className="col-sm-6">
                      <Select
                        onChange={handleCitizenshipSpecificity}
                        value={isCitizenshipSpecific}
                        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                      />
                    </div>
                  </div>
                </div>

                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 pt-4">
                      <label className="gw__form-sections" htmlFor="type">Years Valid</label>
                    </div>

                    <div className="col-sm-6 d-flex">

                      <div className="">
                        <div className="form-check form-check-inline w-100 pt-4">
                          <input
                            className="form-check-input"
                            onChange={handleInput}
                            type="radio"
                            name="validitySelection"
                            checked={validitySelection === '0' || validitySelection === 0}  value="0"
                          />Indefinite
                        </div>
                        <div className="form-check form-check-inline w-100 pt-3">
                          <input
                            className="form-check-input"
                            onChange={handleInput}
                            type="radio"
                            name="validitySelection"
                            checked={validitySelection === '-1' || validitySelection === -1} value="-1"
                          />N/A
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="form-check form-check-inline gw__custom-validity pt-4">
                          <input
                            className="form-check-input"
                            onChange={handleInput}
                            type="radio"
                            name="validitySelection"
                            checked={validitySelection === '1' || validitySelection === 1} value="1"
                          />Custom
                        </div>
                        <RemoveCircleOutlineOutlinedIcon
                          className="gw__number-arrows left"
                          onClick={() => {setValidity(parseInt(validity)-1)}}
                        />
                        <input
                          min={0}
                          className="form-control"
                          type="number"
                          name="validity"
                          disabled={parseInt(validitySelection) !== 1}
                          value={validity}
                          onChange={handleInputNumbers}
                          // placeholder="Enter years valid"
                        />
                        <AddCircleOutlineOutlinedIcon
                          className="gw__number-arrows right"
                          onClick={() => {setValidity(parseInt(validity)+1)}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-6 align-self-center">
                      <label className="gw__form-sections" htmlFor="type">Offers permanent residency?</label>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-check form-check-inline w-100 mb-2">
                        <input
                          className="form-check-input"
                          onChange={handleInput}
                          type="radio"
                          name="permanentResidency"
                          checked={permanentResidency === '1'}  value={'1'}
                        /> Yes
                      </div>
                      <div className="form-check form-check-inline w-100 mb-2">
                        <input
                          className="form-check-input"
                          onChange={handleInput}
                          type="radio"
                          name="permanentResidency"
                          checked={permanentResidency === '0'} value={'0'}
                        /> No
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          onChange={handleInput}
                          type="radio"
                          name="permanentResidency"
                          checked={permanentResidency === '2'} value={'2'}
                        /> N/A
                      </div>
                    </div>
                  </div>
                </div>
                {
                  country && country.isPointsBased &&
                  <div className="formGroup mt-5">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="text-secondary" htmlFor="type">Points Required</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          type="number"
                          name="pointsRequired"
                          value={pointsRequired}
                          onChange={handleInput}
                          placeholder="Enter points required"
                        />
                        {pointsRequired < 0 && <div className="numeric-input-error">Please enter a value of 0 or higher</div>}
                      </div>
                    </div>
                  </div>
                }

                {
                  <CountryMappingSelect
                    workPermit={workPermitData}
                    selectedWorkPermitSourceRegions={selectedWorkPermitSourceRegions}
                    setSelectedWorkPermitSourceRegions={setSelectedWorkPermitSourceRegions}
                  />
                }

                <hr className="mt-5"/>

                <div className="formGroup mt-5">
                  <div className="row mt-5">
                    <div className="col-sm-6">
                      <label className="gw__form-sections">Select the applicable government citizenships</label>
                    </div>
                    <div className="col-sm-6">
                      {
                        <Select
                          isMulti
                          onInputChange={handleCitizenshipPaste}
                          inputValue={pastedString}
                          options={workPermitCitizenshipDropdown}
                          onChange={handleWorkPermitCitizenshipSelection}
                          value={citizenshipValues}
                        />
                      }
                    </div>
                  </div>
                </div>

                <hr className="mt-5"/>

                <div className="formGroup mt-5">
                  <div className="row mt-5">
                    <div className="col-sm-6">
                      <label className="gw__form-sections" htmlFor="type">Select the applicable job levels</label>
                    </div>
                    <div className="col-sm-6">
                      {
                        <Select
                          isMulti
                          options={countryJobLevels}
                          onChange={handleJoblevelSelection}
                          value={jobLevelValues}
                        />
                      }
                    </div>
                  </div>
                </div>

                <hr className="mt-5"/>

                <div className="formGroup mt-5">
                  <div className="row mt-5">
                    <div className="col-sm-6">
                      <label className="gw__form-sections" htmlFor="type">Select the applicable government job codes</label>
                    </div>
                    <div className="col-sm-6">
                      {
                        <Select
                          isMulti
                          options={countryJobCodes}
                          onChange={handleJobSelection}
                          value={jobCodeValues}
                        />
                      }
                    </div>
                  </div>
                </div>

                <hr className="mt-5"/>

                { countryRequirements.find(requirement => requirement.type === 'salaryThreshold') &&
                  <div className="formGroup mt-5">
                    <div className="row mt-5">
                      <div className="col-sm-6">
                        <label className="gw__form-sections mt-3" htmlFor="type">Set the salary range</label>
                        <p>1 will automatically be subtracted from the maximum value when building the range to prevent overlap. <br />E.g. "100 - 200" will be "100 - 199"</p>
                      </div>

                      <table className="table col-sm-6">
                        <thead>
                          <tr>
                            <th>Minimum</th>
                            <th>Maximum</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            salaryThresholds && salaryThresholds.map((salaryRange, index) => {
                              return (
                                  <tr key={index}>
                                    <td className="">
                                      <input className="form-control" type="text" name="minSalary" value={salaryRange.min} onChange={(event) => handleSalaryInput(event, index)} placeholder="Minimum Value" />
                                    </td>
                                    <td className="">
                                      <input className="form-control" type="text" name="maxSalary" value={salaryRange.max} onChange={(event) => handleSalaryInput(event, index)} placeholder="Maximum Value" />
                                    </td>
                                    <td className="gw__center-delete-icon">
                                      <HighlightOffOutlinedIcon className="gw__delete-req-value-icon" onClick={() => removeSalaryRange(index, salaryRange)}/>
                                    </td>
                                  </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>

                      <div className="col-sm-6"></div>
                      <div className="col-sm-6">
                        <button type="button" className="btn btn-outline-green" onClick={addSalaryRange}>
                          <AddCircleOutlineOutlinedIcon className="gw__add-icon"/>
                          ADD VALUE
                        </button>
                      </div>

                    </div>
                    <hr className="mt-5"/>
                  </div>
                }

                <div className="formGroup mt-5">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="gw__form-sections">Select the requirement values for this work permit</h4>
                    </div>
                  </div>
                  {
                    countryRequirements && countryRequirements.map((countryRequirement, index) => {
                      const selectedValues = requirementValues.find(requirement => requirement.id === countryRequirement.id);
                      return (
                        (countryRequirement.type === 'multiSelect' || countryRequirement.type === "existingJobPosition") &&
                        <div key={index} className="row mt-5">
                          <div className="col-sm-6">
                            <label htmlFor="type">{countryRequirement.requirementRevisions[0].name}</label>
                          </div>
                          <div className="col-sm-6">
                            {
                              <Select
                                isMulti
                                options={buildValuesDropdown(countryRequirement.values)}
                                onChange={(event) => handleRequirementValueMultiSelection(event, countryRequirement.id)}
                                value={selectedValues ? selectedValues.values : []}
                              />
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </form>

              <hr className="mt-5" />

              <div className="row mt-5 mb-5">
                <div className="col-sm-12">
                  <div className="gw__form-footer">
                    <Link to={`/countries/${country.id}/work-permits`}><button type="button" className="btn btn-cancel">Cancel</button></Link>
                    <button type="button" className="btn btn-primary" onClick={isEditing ? updateWorkPermit : createWorkPermit} disabled={!isFormValid()}>
                      {isEditing ? 'SAVE CHANGES' : 'SAVE'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
      }
    </React.Fragment>
  );
};

export default AddWorkPermit;
