import React, { Fragment, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { CountryContext } from '../../context/CountryContext';
import { decommissionJobLevel, fetchCountryJobLevels } from '../../services/CountryService';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading.js';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const CountryJobLevel = () => {
  const [jobLevels, setJobLevels] = useState([]);
  const [country, setCountry] = useState(null);
  const [countryId] = useState(localStorage.getItem('countryId'));
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levelJobToDecommissionId, setLevelJobToDecommissionId] = useState(null);

  const countryState = React.useContext(CountryContext);

  useEffect(() => {
    if (!countryId) {
      setRedirectTo('/');
    } else {
      const getJoblevels = async () => {
        setIsLoading(true);
        const countryJobLevels = await fetchCountryJobLevels(countryId);
        const allCountries = countryState[0].countries ? countryState[0].countries : [];
        const selectedCountry = allCountries.filter(country => country.id.toString() === countryId.toString())[0];

        if (selectedCountry && (!country || (country.id !== selectedCountry.id))) {
          setCountry(selectedCountry);
        }
        setJobLevels(countryJobLevels || []);
        setIsLoading(false);
      }
      
      getJoblevels();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState]);

  const toggleDecommissionModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDecommissionJobLevel = async () => {
    setIsLoading(true);

    if (countryId) {
      await decommissionJobLevel(levelJobToDecommissionId); // to be checked
      const getJoblevels = async () => {
        setIsLoading(true);
        const countryJobLevels = await fetchCountryJobLevels(countryId);
        setJobLevels(countryJobLevels || []);
        setIsModalOpen(!isModalOpen);
        setIsLoading(false);
      }
      
      getJoblevels();
    }
  }

  return (
    isLoading
    ? <Loading />
    : redirectTo
      ? <Navigate to={redirectTo} />
      : country &&
        <Fragment>
          <Header showClientNavigation ={false} showImmigrationNavigation={true} displayName={country.name} link={`/select-country`}/>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between">
              <h3 className="mr-5 gw__section-headings">Job Levels</h3>
                <Link to={{
                  pathname: `/countries/${country.id}/job-levels/create`,
                  state: {
                    country,
                    jobLevels
                  }
                }}>
                  <button type="button" className="btn btn-outline-green">
                    <AddCircleOutlineOutlinedIcon className="gw__add-icon"/>
                    New Job Level
                  </button>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {
                  jobLevels.length > 0 ?
                    <Fragment>
                      <table className="table table-hover mt-5">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            jobLevels.map((level, index) => {
                              return (
                                <tr key={index}>
                                  <td>{level.name}</td>
                                  <td >{level.description}</td>
                                  <td>
                                    <Link 
                                      className="gw__nav_links gw__edit-permit-button"
                                      to={{
                                        pathname: `/countries/${country.id}/job-levels/${level.id}/edit`,
                                        state: {
                                          country,
                                          jobLevels
                                        }
                                    }}>
                                    <svg width="2em" height="2em" viewBox="0 0 18 18" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"/>
                                      <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"/>
                                    </svg>
                                    </Link>
                                  </td>
                                  <td>
                                  <HighlightOffOutlinedIcon 
                                    className="gw__delete-permit-icon" 
                                    onClick={() => { setLevelJobToDecommissionId(level.id); toggleDecommissionModal(); }}/>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </Fragment>
                  :
                    <p>There are no job levels configured for this country.</p>
                }
              </div>
            </div>
          </div>

          <Modal isOpen={isModalOpen} toggle={toggleDecommissionModal}>
            <ModalHeader>
              Decommission Job Level
            </ModalHeader>
            <ModalBody>
              <b>Are you sure you want to decommission this country job level?</b><br />
              This country job level, its mappings to company job levels, and work permit requirements will be removed.
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={() => { setLevelJobToDecommissionId(null); toggleDecommissionModal(); }}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleDecommissionJobLevel}>Yes, Remove</button>
            </ModalFooter>
          </Modal>
        </Fragment>
  );
};

export default CountryJobLevel;
