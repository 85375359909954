import React, { Fragment, useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { api } from "../../api";

const VisaCitizenshipProcessingTimeRow = ({
  country,
  destinationCountry,
  setupPageData,
  visa,
  setGovernmentProcessingData,
}) => {
  const [citizenshipProcessingTime, setCitizenshipProcessingTime] = useState(
    visa ? visa.citizenshipProcessingTime : 0
  );
  const [
    citizenshipProcessingTimePremium,
    setCitizenshipProcessingTimePremium,
  ] = useState(visa ? visa.citizenshipProcessingTimePremium : 0);
  const [isNonVisaNation, setIsNonVisaNation] = useState(
    visa ? visa.isNonVisaNation : false
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saveAlCheckBox, setSaveAllCheckbox] = useState(false);
  const [idVisa, setIdVisa] = useState(null);
  const toggleConfirmationModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setCitizenshipProcessingTime(visa ? visa.citizenshipProcessingTime : 0);
    setCitizenshipProcessingTimePremium(
      visa ? visa.citizenshipProcessingTimePremium : 0
    );
    setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visa]);

  useEffect(() => {
    if (
      citizenshipProcessingTime === null ||
      citizenshipProcessingTime === "-" ||
      citizenshipProcessingTimePremium === null ||
      citizenshipProcessingTimePremium === "-"
    )
      return;

    if (idVisa) {
      if (visa) {
        let visaObject = {
          citizenshipProcessingTime,
          citizenshipProcessingTimePremium,
          sourceRegion: country.regions[0].id,
          destinationRegion: destinationCountry.regions[0].id,
          isNonVisaNation,
          id: visa.id,
        };
        setGovernmentProcessingData((prevState) => [...prevState, visaObject]);
      } else {
        let visaObject = {
          citizenshipProcessingTime,
          citizenshipProcessingTimePremium,
          sourceRegion: country.regions[0].id,
          destinationRegion: destinationCountry.regions[0].id,
          processingTime: 0,
          processingTimePremium: 0,
          isNonVisaNation,
        };
        setGovernmentProcessingData((prevState) => [...prevState, visaObject]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAlCheckBox]);

  const saveChanges = async () => {
    if (visa) {
      let visaPayload = {
        citizenshipProcessingTime: citizenshipProcessingTime
          ? citizenshipProcessingTime
          : 0,
        citizenshipProcessingTimePremium: citizenshipProcessingTimePremium
          ? citizenshipProcessingTimePremium
          : 0,
        sourceRegion: country.regions[0].id,
        destinationRegion: destinationCountry.regions[0].id,
        isNonVisaNation,
      };
      await api
        .patch(`/visas/${visa.id}`, visaPayload)
        .catch((error) => console.error(error));
    } else {
      let visaPayload = {
        citizenshipProcessingTime,
        citizenshipProcessingTimePremium,
        sourceRegion: country.regions[0].id,
        destinationRegion: destinationCountry.regions[0].id,
        processingTime: 0,
        processingTimePremium: 0,
        isNonVisaNation,
      };
      await api
        .post(`/visas/`, visaPayload)
        .catch((error) => console.error(error));
    }
    setupPageData();

    setIsEditing(false);
    toggleConfirmationModal();
  };

  const onClickEdit = (sourceRegionId) => {
    setIdVisa(sourceRegionId);
    setIsEditing(true);
  };

  const onChangeSaveAll = () => {
    setSaveAllCheckbox(!saveAlCheckBox);
  };

  return (
    <Fragment>
      <tr>
        <td>{country.name}</td>
        {isEditing ? (
          <Fragment>
            <td>
              <input
                type="checkbox"
                onChange={() => setIsNonVisaNation(!isNonVisaNation)}
                defaultChecked={visa && visa.isNonVisaNation}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                placeholder={visa ? visa.citizenshipProcessingTime : "-"}
                value={citizenshipProcessingTime}
                onChange={(event) =>
                  setCitizenshipProcessingTime(event.target.value)
                }
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                placeholder={visa ? visa.citizenshipProcessingTimePremium : "-"}
                value={citizenshipProcessingTimePremium}
                onChange={(event) =>
                  setCitizenshipProcessingTimePremium(event.target.value)
                }
              />
              <div className="form-check">
                <label
                  className="badge badge-pill badge-primary p-2 mt-2 mr-2"
                  for={country.name}
                >
                  Add in queue to Save All
                </label>
                <input
                  id={country.name}
                  type="checkbox"
                  name="saveAll"
                  onChange={() => onChangeSaveAll()}
                  value={saveAlCheckBox}
                />
              </div>
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={() => toggleConfirmationModal()}
              >
                Save
              </button>
            </td>
            <td>
              <button
                className="btn btn-secondary"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td>
              {visa && visa.isNonVisaNation ? (
                <span>Non-Visa-Nation</span>
              ) : (
                <span>Red Flag Nation</span>
              )}
            </td>
            <td>
              {visa && visa.citizenshipProcessingTime !== 0
                ? visa.citizenshipProcessingTime
                : "-"}
            </td>
            <td>
              {visa && visa.citizenshipProcessingTimePremium !== 0
                ? visa.citizenshipProcessingTimePremium
                : "-"}
            </td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => onClickEdit(country.regions[0].id)}
              >
                Edit
              </button>
            </td>
            <td />
          </Fragment>
        )}
      </tr>
      <Modal size="lg" isOpen={isModalOpen} toggle={toggleConfirmationModal}>
        <ModalHeader className="d-flex justify-content-center text-center">
          <p>{country.name + " to " + destinationCountry.name}</p>
        </ModalHeader>
        <ModalBody>
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Processing Time</th>
                <th scope="col"></th>
                <th scope="col">Premium Processing Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">From</th>
                <td>{visa ? visa.citizenshipProcessingTime : "-"}</td>
                <th scope="row">From</th>
                <td>{visa ? visa.citizenshipProcessingTimePremium : "-"}</td>
              </tr>
              <tr>
                <th scope="row">To</th>
                <td>{citizenshipProcessingTime}</td>
                <th scope="row">To</th>
                <td>{citizenshipProcessingTimePremium}</td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-center my-4 text-center">
            <p>
              Are you sure you want to make these changes to the citizenship
              processing time?{" "}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleConfirmationModal}
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => saveChanges()}
            className="btn btn-danger"
          >
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default VisaCitizenshipProcessingTimeRow;
