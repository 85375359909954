import React, { useState, useEffect, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { api } from "../../api";

const VisaGovernmentProcessingRow = ({
  country,
  destinationCountry,
  visasRes,
  setupPageData,
  setGovernmentProcessingData,
}) => {
  const { regions } = country;
  const [isEditing, setIsEditing] = useState(false);
  const [processingTime, setProcessingTime] = useState(null);
  const [processingTimePremium, setProcessingTimePremium] = useState(null);

  const [previousProcessing, setPreviousProcessing] = useState(null);
  const [previousProcessingPremium, setPreviousProcessingPremium] = useState(null);
  const [sourceRegionName, setSourceRegionName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedRegionId, setUpdatedRegionId] = useState(null);
  const [updatedVisaId, setUpdatedVisaId] = useState(null);
  const regionId = localStorage.getItem("regionId");
  const [idVisa, setIdVisa] = useState(null);
  const [sourceRegionId, setSourceRegionId] = useState(null);
  const [saveAlCheckBox, setSaveAllCheckbox] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setProcessingTime(visa ? visa.processingTime : 0);
    setProcessingTimePremium(visa ? visa.processingTimePremium : 0);
    setSourceRegionId(regions[0].id);
    setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visasRes, country]);

  useEffect(() => {
    if (
      processingTime === null ||
      processingTime === "-" ||
      processingTimePremium === null ||
      processingTimePremium === "-"
    )
      return;
    if (!idVisa) {
      const visaObject = {
        processingTime,
        processingTimePremium,
        sourceRegion: sourceRegionId,
        destinationRegion: regionId,
        citizenshipProcessingTime: 0,
        citizenshipProcessingTimePremium: 0,
        isNonVisaNation: false,
      };
      setGovernmentProcessingData((prevState) => [...prevState, visaObject]);
    } else {
      const visaObject = {
        idVisa,
        processingTime,
        processingTimePremium,
      };
      setGovernmentProcessingData((prevState) => [...prevState, visaObject]);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAlCheckBox]);

  const createVisa = async (sourceRegionId) => {
    try {
      const visaObject = {
        processingTime,
        processingTimePremium,
        sourceRegion: sourceRegionId,
        destinationRegion: regionId,
        citizenshipProcessingTime: 0,
        citizenshipProcessingTimePremium: 0,
        isNonVisaNation: false,
      };
      await api.post(`/visas`, visaObject);
    } catch (error) {
      console.error("Error createVisa", error);
    }
  };

  const updateVisa = async (visaId) => {
    try {
      const visaObject = {
        processingTime,
        processingTimePremium,
      };
      await api.patch(`/visas/${visaId}`, visaObject);
    } catch (error) {
      console.error("Error updateVisa", error);
    }
  };

  const getVisa = (selectedVisaSourceRegion) => {
    const visa = visasRes.find((visa) =>
      returnMatchingVisa(visa, selectedVisaSourceRegion)
    );
    if (visa) {
      return visa;
    } else {
      return {
        id: null,
        processingTime: "0",
        processingTimePremium: "0",
      };
    }
  };

  const returnMatchingVisa = (visa, selectedVisaSourceRegion) => {
    return (
      visa.sourceRegion.id.toString() === selectedVisaSourceRegion.toString() &&
      visa.destinationRegion.id.toString() === regionId.toString()
    );
  };

  const visa = getVisa(regions[0].id);

  const onClickEdit = (visa, sourceRegionId) => {
    setSourceRegionId(sourceRegionId);
    setIdVisa(visa.id);
    setIsEditing(true);
    setProcessingTime(visa ? visa.processingTime : 0);
    setProcessingTimePremium(visa ? visa.processingTimePremium : 0);
    setPreviousProcessing(visa ? visa.processingTime : "0");
    setPreviousProcessingPremium(visa ? visa.processingTimePremium : "0");
  };

  const onClickSave = (sourceRegionId, visaId) => {
    if (visaId) {
      updateVisa(visaId);
    } else {
      createVisa(sourceRegionId);
    }
    setIsEditing(false);
    toggle();
    setIsChanged(false);
    setupPageData();
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    setIsChanged(!isChanged);
  };

  const onChangeProcessingTime = (event) => {
    setIsChanged(true);
    setProcessingTime(event.target.value);
  };

  const onChangeProcessingTimePremium = (event) => {
    setIsChanged(true);
    setProcessingTimePremium(event.target.value);
  };

  const onChangeSaveAll = () => {
    setIsChanged(false);
    setSaveAllCheckbox(!saveAlCheckBox);
  };

  const onClickSaveChanges = (regionName, regionId, visaId) => {
    toggle();
    setSourceRegionName(regionName);
    setUpdatedRegionId(regionId);
    setUpdatedVisaId(visaId);
  };
  
  return (
    <Fragment>
      {isEditing ? (
        <tr>
          <td>
            {country.name +
              (country.name === regions[0].name ? "" : " - " + regions[0].name)}
          </td>
          <td>
            <input
              type="number"
              name="processingTime"
              onChange={(event) => onChangeProcessingTime(event)}
              placeholder={visa.processingTime}
              value={processingTime}
            />
          </td>
          <td>
            <input
              type="number"
              name="processingTimePremium"
              onChange={(event) => onChangeProcessingTimePremium(event)}
              placeholder={visa.processingTimePremium}
              value={processingTimePremium}
            />
            <div className='form-check'>
            <label className='badge badge-pill badge-primary p-2 mt-2 mr-2' for={country.name}>Add in queue to Save All</label>
              <input
                id={country.name}
                type="checkbox"
                name="saveAll"
                onChange={() => onChangeSaveAll()}
                value={saveAlCheckBox}        
              />
            </div>
          </td>
          <td>
            <button
              disabled={!isChanged}
              type="button"
              onClick={() =>
                onClickSaveChanges(
                  country.name +
                    (country.name === regions[0].name
                      ? ""
                      : " - " + regions[0].name),
                  regions[0].id,
                  visa.id
                )
              }
              className="btn btn-danger"
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {setIsEditing(!isEditing); setIsChanged(!isChanged)}}
              style={{ padding: "0 0" }}
            >
              Cancel
            </button>
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            {country.name +
              (country.name === regions[0].name
                ? ""
                : " - " + regions[0].name)}{" "}
          </td>
          <td>{visa.processingTime}</td>
          <td>{visa.processingTimePremium}</td>
          <td>
            <button
              onClick={() => {
                onClickEdit(visa, regions[0].id);
              }}
              className="btn btn-primary"
            >
              Edit
            </button>
          </td>
        </tr>
      )}

      <Modal size="lg" isOpen={isModalOpen} toggle={toggle}>
        <ModalHeader className="d-flex justify-content-center text-center">
          {country && (
            <div>
              <h4>
                {" "}
                {sourceRegionName + "  -->  "}
                {destinationCountry.name}
              </h4>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Processing Time</th>
                <th scope="col">Premium Processing Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">From</th>
                <td>{previousProcessing}</td>
                <td>{previousProcessingPremium}</td>
              </tr>
              <tr>
                <th scope="row">To</th>
                <td>{processingTime}</td>
                <td>{processingTimePremium}</td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-center my-4 text-center">
            <h6>
              Are you sure you want to make these changes to visa processing
              time?{" "}
            </h6>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary" onClick={toggle}>
            Close
          </button>
          <button
            type="button"
            onClick={() => onClickSave(updatedRegionId, updatedVisaId)}
            className="btn btn-danger"
          >
            Save changes
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default VisaGovernmentProcessingRow;
