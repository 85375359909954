import React, { Component } from 'react';
import {api} from '../../api';
import Header from '../../general/navigation/Header';

class ManageJobPositions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      selectedCompany: null,
      companyPositions: [],
      companyId: localStorage.getItem('companyId')
    };
  }

  componentDidMount() {
    if (!this.state.companyId) {  
      window.location.replace('/#/');
    } else {
      api.get(`/companies/`+ this.state.companyId)
      .then(response => {
        this.setState({
          selectedCompany: response.data,
        });
      });

      api.get('/companies/'+ this.state.companyId+'/job-positions')
      .then(response => {
        this.setState({
          companyPositions: response.data,
        });
      });
    }
  }

  addJobPosition = () => {
    const {companyPositions} = this.state;
    companyPositions.push({id: null, jobFamily: ""});
    this.setState({companyPositions});
  }

  onChangePosition = (event, index) => {
    const {companyPositions} = this.state;
    companyPositions[index][event.target.name] = event.target.value;
    this.setState({companyPositions});
  }

  removePosition = (index) => {
    const {companyPositions} = this.state;
    companyPositions.splice(index,1);
    this.setState({companyPositions});
  }

  savePositions = () => {
    if(!this.checkJobPositionsFilled()){
      alert('Please fill job positions and ensure there are no duplicates before submission.');
    } else{
      api.patch('/companies/' + this.state.selectedCompany.id + '/job-positions', this.state.companyPositions)
      .then(response => {
        alert('Positions saved.')
      });
    }
  }

  checkJobPositionsFilled = () => {
    let positionsValid = true;
    for(const position of this.state.companyPositions) {
      let duplicatePosition = this.state.companyPositions.filter(companyPosition => (companyPosition.jobFamily.trim() === position.jobFamily.trim()));
      if(duplicatePosition.length > 1) {
        positionsValid = false;
      }
      if (!position.jobFamily) {
        positionsValid = false;
      }
    }
    return positionsValid;
  }

  render() {
    return (
      <React.Fragment>
        {this.state.selectedCompany &&
          <React.Fragment>
            <Header showClientNavigation ={true} showImmigrationNavigation={false} displayName={this.state.selectedCompany.name} link={"/select-company"}/>  
            <h3 className="container text-secondary text-bold pb-4">Job Positions</h3>
            <div className="container manage-countries-container d-flex justify-content-center align-items-center">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-secondary w-25" scope="col">Job Position</th>
                    <th scope="col"></th>
                    <th className="text-secondary" scope="col">Mapped Job Codes</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.companyPositions.map((position, index) =>
                    <tr key={index}>
                      <td className="text-secondary">
                        <input onChange={(event) => this.onChangePosition(event, index)} name="jobFamily" value={this.state.companyPositions[index].jobFamily} type="text" className="form-control"/>
                      </td>
                      <td>
                      <button type="button" onClick ={() => this.removePosition(index)} className="btn btn-danger">X</button>
                      </td>
                      <td>
                        <select disabled multiple className="form-control listed-job-codes">
                        {this.state.companyPositions[index].jobCodes &&
                          <React.Fragment>
                            {this.state.companyPositions[index].jobCodes.map((jobCode, jobCodeIndex) =>
                              <option key={position.id + jobCode.name + jobCodeIndex}>{jobCode.name}</option>
                            )}
                          </React.Fragment>
                        }
                        </select>
                      </td>
                    </tr>
                  )} 
                </tbody>
              </table>
              <button type="button" onClick={this.addJobPosition} className="btn btn-secondary my-3">Add New Job Position</button>
              <button type="button" className="btn btn-primary" onClick={this.savePositions}>Save Changes</button>
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default ManageJobPositions;