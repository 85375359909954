import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Header from '../../general/navigation/Header';
import { fetchCountry, fetchCountryRequirements } from '../../services/CountryService';
import { fetchRequirement, createRequirement, updateRequirement, createValue, updateValue, removeValues } from '../../services/requirementServices';

import Loading from '../../general/components/Loading';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const requirementType = {
  SINGLE_SELECT: 'singleSelect',
  MULTI_SELECT: 'multiSelect',
  NUMERIC: 'numeric',
  EXISTING_JOB_POSITION: 'existingJobPosition',
  CLIENT_SPECIFIC_QUESTION: 'clientSpecificQuestion'
};

const RequirementForm = () => {
  // Country state
  const { id: requirementId } = useParams();
  const [countryId] = useState(localStorage.getItem('countryId'));
  const [regionId] = useState(localStorage.getItem('regionId'));
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);

  // Requirement state
  const [requirements, setRequirements] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [question, setQuestion] = useState('');
  const [prefix, setPrefix] = useState('');
  const [type, setType] = useState(requirementType.MULTI_SELECT);
  const [values, setValues] = useState(requirementId ? [] : [[[{id: null, value: '', min: '', max: '', weight: null}]]]);
  const [valuesToBeDeleted, setValuesToBeDeleted] = useState([]);

  // General states
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequirementNameValid, setIsRequirementNameValid] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const countryResponse = await fetchCountry(countryId);
      const countryRequirementsResponse = await fetchCountryRequirements(countryId, regionId);

      let initialRegion = null;
      initialRegion = countryResponse.regions.filter(region => region.id.toString() === region.toString())[0];

      // Set states
      setCountry(countryResponse);
      setRequirements(countryRequirementsResponse);
      setRegion(initialRegion);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchRequirementData = async () => {
      const requirementResponse = await fetchRequirement(requirementId);

      if (requirementResponse.values) {
        let tempValues = values.slice();
        tempValues = [[{id: null, value: '', min: '', max: '', weight: null}]];
        let valueStateIndex = 0;

        for (let i = 0; i < requirementResponse.values.length; i++) {
          if (requirementResponse.values[i].requirementValueRevisions[0].value !== 'Not Required' && !requirementResponse.type.CLIENT_SPECIFIC_QUESTION) {
            tempValues[valueStateIndex].id = requirementResponse.values[i].id;
            if ((requirementResponse.type === requirementType.SINGLE_SELECT) || requirementResponse.type === requirementType.MULTI_SELECT
            || requirementResponse.type === requirementType.EXISTING_JOB_POSITION)  {
              tempValues[valueStateIndex].value = requirementResponse.values[i].requirementValueRevisions[0].value;
            } else if (requirementResponse.type === requirementType.NUMERIC) {
              let numericSplit = requirementResponse.values[i].requirementValueRevisions[0].value.split('-');
              tempValues[valueStateIndex].min = numericSplit[0];

              if (numericSplit[1]) {
                tempValues[valueStateIndex].max = numericSplit[1];
              }

              tempValues[valueStateIndex].weight = requirementResponse.values[i].requirementValueRevisions[0].weight;
            }
            tempValues.push({value: '', min: '', max: '', weight: null});
            valueStateIndex++;
          }
        }

        if (tempValues[tempValues.length-1].value === '') {
          tempValues.pop();
          valueStateIndex--;
        }
        setValues(tempValues);
      }

      // Set state
      setIsEditing(true);
      setName(requirementResponse.requirementRevisions[0].name);
      setDescription(requirementResponse.requirementRevisions[0].description);
      setQuestion(requirementResponse.requirementRevisions[0].question);
      setPrefix(requirementResponse.requirementRevisions[0].prefix);
      setType(requirementResponse.type);
    }

    if (requirementId) fetchRequirementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementId]);

  useEffect(() => {
    if (!isRequirementNameValid) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [isRequirementNameValid]);

  const toggleModal = async () => { setIsModalOpen(!isModalOpen) };

  const handleInput = (event) => {
    const updateValues = {
      name: setName,
      description: setDescription,
      question: setQuestion,
      prefix: setPrefix,
      type: setType,
      values: setValues,
    }

    if (event.target.name === 'type') {
      setType(event.target.value);
      setPrefix('');
      setValues([{value: '', min: '', max: '', weight: null}]);
    } else if (event.target.name === 'name') {
      setName(event.target.value);
      setIsRequirementNameValid(true);
    } else {
      updateValues[event.target.name] && updateValues[event.target.name](event.target.value)
    }
  };

  const handleValuesInput = (event, index) => {
   const tempValues = values.slice();

   tempValues[index][event.target.name] = event.target.value;
   setValues(tempValues);
  };

  function addValueToRequirement() {
    const tempValues = values.slice();
    tempValues.push({
      value: '',
      min: '',
      max: '',
      weight: null,
    });
    setValues(tempValues);
  };

  function removeValueFromRequirement(index, value) {
    if (value.id) {
      const tempValuesToBeDeleted = valuesToBeDeleted.slice();
      tempValuesToBeDeleted.push(value.id);
      setValuesToBeDeleted(tempValuesToBeDeleted);
    }

    const tempValues = values.slice();
    tempValues.splice(index, 1);
    setValues(tempValues);
  };

  const isFormValid = () => {
    let isFormValid = true;

    if (type === 'salaryThreshold' || type === 'clientSpecificQuestion') {
      return isFormValid;
    }

    if (!name || !question ||
      name.includes("<") || name.includes(">") ||
      question.includes("<") || question.includes(">")) {
      isFormValid = false;
    }

    if (values.length === 0 && valuesToBeDeleted.length === 0) {
      isFormValid = false;
    }

    values.forEach(value => {
      if ((type === requirementType.SINGLE_SELECT || type === requirementType.MULTI_SELECT || type === requirementType.EX) && !value.value) {
        isFormValid = false;
      } else if (type === requirementType.NUMERIC) {
        if (!value.min) {
          isFormValid = false;
        }
      }
    });

    return isFormValid;
  };

  const validateRequirementName = () => {
    let isNameValid = true;

    requirements.forEach(requirement => {
      if (requirement.requirementRevisions[0].name === name) {
        isNameValid = false;
      }
    });

    return isNameValid;
  };

  const handleCreateRequirement = async () => {
    if (validateRequirementName()) {
      setIsLoading(true);
      toggleModal();
      setIsRequirementNameValid(true);
      const formattedValues = formatValues(values);

      let requirementToBeCreated = {
        name: name,
        description: description,
        question: question,
        prefix: prefix,
        type: type,
        region: regionId,
        values: formattedValues,
      };
      await createRequirement(countryId, requirementToBeCreated);
      toggleModal();
      redirectToRequirements();
      setIsLoading(false);
    }
    setIsRequirementNameValid(false);
    toggleModal();
  };

  const handleUpdateRequirement = async () => {
    setIsLoading(true);
    toggleModal();
    setIsRequirementNameValid(true);
    const formattedValues = formatValues(values);
    const requirementToBeUpdated = {
      name: name,
      description: description,
      question: question,
      prefix: prefix,
    };

    // Update the requirement details
    await updateRequirement(requirementId, requirementToBeUpdated);
    if (type === 'multiSelect' || type === 'existingJobPosition') {
      await handleRemoveValues();
      for (const value of formattedValues) {
        if (!value.id) {
          await handleCreateValue(value);
        } else {
          await updateValue(value);
        }
      }
    }

    await redirectToRequirements();
    setIsLoading(false);
  };

  const handleRemoveValues = async () => {
    for (let valueToBeDeleted of valuesToBeDeleted) {
      await removeValues(valueToBeDeleted);
    }
  };

  const handleCreateValue = async (value) => {
    let valueToCreate = {
      value: value.value,
      requirementId: parseInt(requirementId, 10),
      weight: value.weight,
    }

    await createValue(valueToCreate);
  };

  const redirectToRequirements = async () => {
    await setIsLoading(false);
    window.location.replace(`/#/countries/${country.id}/requirements`);
  };

  const formatValues = (values) => {
    let formattedValues = [];
    if (type === requirementType.SINGLE_SELECT || type === requirementType.MULTI_SELECT || type === requirementType.EXISTING_JOB_POSITION) {
      values.forEach(value => {
        formattedValues.push({
          value: value.value,
          weight: value.weight,
          id: value.id,
        });
      });
    } else if (type === requirementType.NUMERIC) {
      values.forEach(value => {
        if (!value.max || value.max === 0) {
          formattedValues.push({
            value: value.min.toString(),
            weight: value.weight,
            id: value.id,
          });
        } else {
          formattedValues.push({
            value: value.min.toString().concat('-', value.max.toString()),
            weight: value.weight,
            id: value.id,
          });
        }
      })
    }

   return formattedValues;
  };

  return (
    <React.Fragment>
    {country &&
    <React.Fragment>
      <Header showClientNavigation ={false} showImmigrationNavigation={true} displayName={country.name + (country.regions.length > 1 ? " - " + region.name : "")} link={"/select-country"}/>
      {isLoading
        ? <Loading />
        : <div className="container gw__req-form-anchor">
            {isEditing
              ? <h3 className="gw__section-headings">Edit Requirement</h3>
              : <h3 className="gw__section-headings">Create Requirement</h3>
            }
          <form>
            <div className="formGroup mt-5">
              <div className="row">
                <div className="col-sm-6 align-self-center">
                  <label className="gw__form-sections" htmlFor="name">Name</label>
                </div>
                <div className="col-sm-6 pl-0">
                  <input
                    className={"form-control" + (!isRequirementNameValid ? " gw__input-error" : "")}
                    type="text"
                    name="name"
                    maxLength={255}
                    value={name}
                    onChange={handleInput}
                    placeholder="Enter requirement name"
                  />
                  {!isRequirementNameValid && <p className="gw__invalid-name">REQUIREMENT NAME IN USE</p>}
                </div>
              </div>
            </div>

            <div className="formGroup mt-5">
              <div className="row">
                <div className="col-sm-6 align-self-center">
                  <label className="gw__form-sections" htmlFor="question">Question</label>
                </div>
                <div className="col-sm-6 pl-0">
                  <input
                    className="form-control"
                    type="text"
                    name="question"
                    maxLength={255}
                    value={question}
                    onChange={handleInput}
                    placeholder="Enter requirement question"
                  />
                </div>
              </div>
            </div>

            <div className="formGroup mt-5">
              <div className="row">
                <div className="col-sm-6 align-self-center">
                  <label className="gw__form-sections" htmlFor="description">
                    Description
                    <p className="gw__secondary-label">This is hint text for the recruiter</p></label>
                </div>
                <div className="col-sm-6 pl-0">
                  <TextareaAutosize
                    className="form-control text-area-sizing"
                    type="text"
                    name="description"
                    value={description}
                    onChange={handleInput}
                    placeholder="Enter requirement description"
                    onKeyPress={e => {
                      if(e.key === 'Enter')
                         e.preventDefault()
                    }} // Don't allow enter key it's not stored in the DB.
                  />
                </div>
              </div>
            </div>

            {
              type === requirementType.NUMERIC &&
              <div className="formGroup mt-5">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="gw__form-sections" htmlFor="type">Prefix</label>
                  </div>
                  <div className="col-sm-6 pl-0">
                    <input className="form-control" type="text" name="prefix" value={prefix} onChange={handleInput} placeholder="Enter prefix" />
                  </div>
                </div>
              </div>
            }

            { (type !== 'salaryThreshold' && type !== 'clientSpecificQuestion') &&
            <Fragment>
              <hr className="mt-5 mb-5" />

              <div className="formGroup">
                <div className="row gw__table-container">
                  <div className="col-sm-6">
                    <p className="gw__form-sections mt-3">Enter the values for the requirement</p>
                  </div>

                {/* REQUIREMENT VALUES SECTION */}
                <table className="table col-sm-6">
                  <thead>
                    <tr>
                      <th className="pl-0" scope="col">REQUIREMENT VALUE</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      type !== requirementType.NUMERIC && values.length > 0 ?
                        values.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td className="col-sm-12 mt-3 pl-0">
                                <input className="form-control" type="text" name="value" value={values[index].value} onChange={(event) => handleValuesInput(event, index)} placeholder="Enter a value" />
                              </td>
                              {
                                country && country.isPointsBased &&
                                <td className="col-sm-12 mt-3 pl-0">
                                  <input className="form-control" type="number" name="weight" value={values[index].weight} onChange={(event) => handleValuesInput(event, index)} placeholder="Enter weight" />
                                </td>
                              }
                              <td className="gw__center-delete-icon">
                                <HighlightOffOutlinedIcon className="gw__delete-req-value-icon" onClick={() => removeValueFromRequirement(index, value)}/>
                              </td>
                            </tr>
                          )
                        })
                      :
                        values.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td className="col-sm-3 mt-3 pl-0">
                                <input className="form-control" type="number" name="min" value={values[index].min} onChange={(event) => handleValuesInput(event, index)} placeholder="Enter minimum value" />
                              </td>
                              <td className="col-sm-3 mt-3 pl-0">
                                <input className="form-control" type="number" name="max" value={values[index].max} onChange={(event) => handleValuesInput(event, index)} placeholder="Enter maximum value" />
                              </td>
                              {
                                country && country.isPointsBased &&
                                <td className="col-sm-3 mt-3 pl-0">
                                  <input className="form-control" type="number" name="weight" value={values[index].weight} onChange={(event) => handleValuesInput(event, index)} placeholder="Enter weight" />
                                </td>
                              }
                              <td className="mt-3 align-self-center">
                                <HighlightOffOutlinedIcon className="gw__delete-req-value-icon" onClick={() => removeValueFromRequirement(index, value)}/>
                              </td>
                            </tr>
                          )
                        })
                    }
                  </tbody>
                </table>
                <div className="col-sm-6"></div>
                <div className="col-sm-6 mt-3 align-items-right pl-0">
                      <button type="button" className="btn btn-outline-green" onClick={addValueToRequirement}>
                        <AddCircleOutlineOutlinedIcon className="gw__add-icon"/>
                        ADD VALUE
                      </button>
                    </div>
                </div>
              </div>
            </Fragment>
          }
          </form>

          <hr className="mt-5"/>

          <div className="row mt-5">
            <div className="col-sm-12">
              <div className="gw__form-footer mb-5">
                <Link to={`/countries/${country.id}/requirements`}><button type="button" className="btn btn-cancel">Cancel</button></Link>
                <button type="button" className="btn btn-primary" onClick={toggleModal} disabled={!isFormValid()}>
                  {isEditing ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          </div>

          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalBody>
              {isEditing
                ? <p>Are you sure you want to update this requirement?</p>
                : <p>This requirement will apply to all work permits listed on this country with a value of "Not Required", would you like to proceed?</p>
              }
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={toggleModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={isEditing ? handleUpdateRequirement : handleCreateRequirement}>{isEditing ? 'Update' : 'Save'}</button>
            </ModalFooter>
          </Modal>
        </div>
      }
    </React.Fragment>
    }
    </React.Fragment>
  );
};

export default RequirementForm;
