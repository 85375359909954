import React, { Fragment, useContext, useEffect } from 'react';
import {Link} from'react-router-dom';
import Header from '../../general/navigation/Header';
import Select from 'react-select';
import { CountryContext } from '../../context/CountryContext';

const SelectCountry = () => {
  const [countryState, countryDispatch] = useContext(CountryContext);

  useEffect(() => {
    countryDispatch({
      type: 'SELECT_COUNTRY',
      selectedCountry: null,
    });

    localStorage.removeItem('regionId');
    localStorage.removeItem('countryId');
    localStorage.removeItem('companyId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Returns an array of value-label pairs for the country options in the dropdown.
   */
  const returnCountries = () => {
    const options = [];
    for (const country of countryState.countries) {
      options.push({value: (country), label: (country.name)})
    }
    return options;
  }

  /**
   * Set the selected country and region once and object has been selected from the dropdown.
   * @param {object} event upon the change of country select
   */
  const handleCountrySelect = (event) => {
    localStorage.removeItem('regionId');
    // Get the selected country from the list of countries in the current state.
    const selectCountry = event.value;

    // Set the selected country.
    countryDispatch({
      type: 'SELECT_COUNTRY',
      selectedCountry: selectCountry,
    });

    localStorage.setItem('countryId', selectCountry.id);
    localStorage.setItem('regionId', selectCountry.regions[0].id);
  }

  /**
   * Update the selected region if there is a region in the country and it has been selected.
   * @param {object} event upon change in the region select dropdown
   */
  const handleRegionSelect = (event) => {
    let region = countryState.selectedCountry.regions.filter(region => region.name === event.target.value)[0];
    localStorage.setItem('regionId', region.id);
  }

  return (
    <Fragment>
      <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
      <div className="select-country-container d-flex justify-content-center align-items-center flex-wrap">
        <form>
          <div className="form-group">
            <div className="label">COUNTRY</div>
            <Select 
              className="landing-page-select" 
              onChange={(event) => handleCountrySelect(event)} 
              options={returnCountries()}
            />
          </div>
        </form>
        {countryState.selectedCountry && countryState.selectedCountry.regions.length > 1 &&
          <form>
            <div className="form-group">
              <h3 className="text-secondary font-weight-light">Please select country region:</h3>
              <select defaultValue="" onChange={handleRegionSelect} className="form-control" id="countrySelect">
              <option value="" disabled>Please select</option>|
                {countryState.selectedCountry.regions.map((region, key) =>
                  <option key={region.id}>{region.name}</option>
                )}
              </select>
            </div>
          </form>
        }
        {
          countryState.selectedCountry && <Link to={`/countries/${countryState.selectedCountry.id}/requirements`}>Continue</Link>
        }
      </div>
    </Fragment>
  );
};
export default SelectCountry;